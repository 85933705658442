<template>
  <div class="error_main_div">
    <div class="error_inr_div">
      <div class="error_wrrper_div">
        <div class="error_content_div">
          <h1>4<span>0</span>4</h1>
          <p class="error_p_one"><span>Oops! </span>Page not found </p>
          <p class="error_p_two"> The page you’re looking for does not exist. </p>    
          <router-link to="/" class="hover">Go home
          </router-link>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
    // -next-line vue/multi-word-component-names
    name:'Errorpage'
}
</script>
<style lang="postcss" scoped>
.error_main_div{ @apply flex justify-center h-full;
  .error_inr_div{ @apply lg:py-12;
    .error_wrrper_div{ @apply lg:gap-4 lg:flex;
      .error_content_div{ @apply flex flex-col items-center justify-center;
        h1{ @apply font-bold text-borderclr text-7xl;
          span{ @apply text-whitergb;
          }
        }
        .error_p_one{ @apply mb-2 text-2xl font-bold text-center text-whitergb md:text-3xl;
          span{ @apply text-borderclr;
          }
        }
        .error_p_two{ @apply mb-8 text-center text-gray-500 md:text-lg;
        }
        .hover{ @apply px-6 py-2 text-sm font-semibold text-black rounded-lg bg-borderclr;
          &:hover{ @apply hover:bg-gradient-to-br from-hover to-hover;
          }
        }
      }
    }
  }
}
.login_robot {
    @apply absolute left-0 right-0 m-auto top-32;
}

</style>