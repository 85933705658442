<template>
  <section class="main_section">
    <div class="heading">
      <h1 v-show="!otpsent"><span>Enter</span> Fredoverse</h1>
    </div>
    <div class="login_main_div">
      <div class="login_inr_div">
        <div class="login_col1">
          <div class="login_img_inr_div">
            <img
              src="../assets/image/login_robot.png"
              alt=""
              class="login_robot robot -z-[1]"
            />
            <svg
              width="318"
              height="535"
              viewBox="0 0 358 633"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                class="shadow"
                d="M21.7869 456.016C21.7869 435.296 92.0282 418.5 178.675 418.5C265.322 418.5 335.564 435.296 335.564 456.016"
                stroke="#00FFFF"
                stroke-width="11.3687"
              />
              <g class="shadow" filter="url(#filter0_f_2_2394)">
                <path
                  d="M21.7869 456.016C21.7869 435.296 92.0282 418.5 178.675 418.5C265.322 418.5 335.564 435.296 335.564 456.016"
                  stroke="#00FFFF"
                  stroke-width="11.3687"
                />
              </g>
              <ellipse
                cx="179"
                cy="505.584"
                rx="149.174"
                ry="29.4775"
                transform="rotate(-180 179 505.584)"
                fill="url(#paint0_linear_2_2394)"
                fill-opacity="0.8"
              />
              <g opacity="0.8" filter="url(#filter1_f_2_2394)">
                <ellipse
                  rx="69.494"
                  ry="46.1312"
                  transform="matrix(0.0796604 0.996822 -0.955672 0.294434 179.262 427.653)"
                  fill="#00FFFF"
                />
              </g>
              <rect
                x="20"
                y="0.893066"
                width="318"
                height="484.993"
                fill="url(#pattern0)"
              />
              <g filter="url(#filter2_f_2_2394)">
                <ellipse
                  cx="179.228"
                  cy="153.193"
                  rx="35.9399"
                  ry="46.0028"
                  fill="#00FFFF"
                />
              </g>
              <path
                opacity="0.25"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.826 25.0331L29.826 505.584L29.826 507.371L30.0955 507.371C34.769 522.819 99.6493 535.062 179 535.062C258.351 535.062 323.231 522.819 327.905 507.371L328.174 507.371L328.174 505.607L328 505.584L328.174 505.562L328.174 23.2248L327.771 23.2248C322.117 10.739 257.67 0.893121 179 0.893114C100.33 0.893108 35.883 10.7389 30.229 23.2247L29.826 23.2247L29.826 24.989C29.826 24.9964 29 25.0037 29.826 25.0111C29.826 25.0184 29.826 25.0258 29.826 25.0331Z"
                fill="url(#paint1_linear_2_2394)"
              />
              <ellipse
                cx="179"
                cy="25.0112"
                rx="149.174"
                ry="25.0112"
                transform="rotate(-180 179 25.0112)"
                fill="url(#paint2_linear_2_2394)"
                fill-opacity="0.8"
              />

              <defs>
                <filter
                  id="filter0_f_2_2394"
                  x="4.73381"
                  y="401.446"
                  width="347.883"
                  height="65.9386"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="5.68436"
                    result="effect1_foregroundBlur_2_2394"
                  />
                </filter>
                <filter
                  id="filter1_f_2_2394"
                  x="0.835709"
                  y="223.059"
                  width="356.852"
                  height="409.189"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="66.9944"
                    result="effect1_foregroundBlur_2_2394"
                  />
                </filter>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlink:href="#image0_2_2394"
                    transform="translate(-0.481742) scale(0.000490871 0.000321854)"
                  />
                </pattern>
                <filter
                  id="filter2_f_2_2394"
                  x="36.0966"
                  y="-0.000328064"
                  width="286.262"
                  height="306.388"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="53.5955"
                    result="effect1_foregroundBlur_2_2394"
                  />
                </filter>
                <filter
                  id="filter3_f_2_2394"
                  x="4.73332"
                  y="444.648"
                  width="347.883"
                  height="65.9386"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="5.68436"
                    result="effect1_foregroundBlur_2_2394"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_2_2394"
                  x1="328.174"
                  y1="505.584"
                  x2="35.6322"
                  y2="505.584"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#373737" />
                  <stop offset="1" stop-color="#171717" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2_2394"
                  x1="328.174"
                  y1="127.511"
                  x2="55.1151"
                  y2="339.526"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00A3A3" />
                  <stop offset="1" stop-color="#00FFFF" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_2_2394"
                  x1="328.174"
                  y1="25.0112"
                  x2="35.6322"
                  y2="25.0112"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#373737" />
                  <stop offset="1" stop-color="#171717" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              class="absolute top-0 -z-10"
              width="318"
              height="535"
              viewBox="0 0 358 633"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                class="shadow"
                d="M335.563 456.016C335.563 476.736 265.322 493.533 178.675 493.533C92.0277 493.533 21.7864 476.736 21.7864 456.016"
                stroke="#00FFFF"
                stroke-width="11.3687"
              />
              <g class="shadow" filter="url(#filter3_f_2_2394)">
                <path
                  d="M335.563 456.016C335.563 476.736 265.322 493.533 178.675 493.533C92.0277 493.533 21.7864 476.736 21.7864 456.016"
                  stroke="#00FFFF"
                  stroke-width="11.3687"
                />
              </g>
            </svg>
            <svg
              class="absolute top-0"
              width="318"
              height="535"
              viewBox="0 0 358 633"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                class="shadow"
                d="M21.7869 456.016C21.7869 435.296 92.0282 418.5 178.675 418.5C265.322 418.5 335.564 435.296 335.564 456.016"
                stroke="#00FFFF"
                stroke-width="11.3687"
              />
              <g class="shadow" filter="url(#filter0_f_2_2394)">
                <path
                  d="M21.7869 456.016C21.7869 435.296 92.0282 418.5 178.675 418.5C265.322 418.5 335.564 435.296 335.564 456.016"
                  stroke="#00FFFF"
                  stroke-width="11.3687"
                />
              </g>
            </svg>
          </div>
        </div>

        <div class="login_col2">
          <div class="form_main_div" v-if="!otpsent && !userExists">
            <form class="form" @submit.prevent="handelMobileOTP">
              <div class="column">
                <label>Phone no</label>
                <input
                  class="focus:outline-none focus:shadow-outline"
                  maxlength="10"
                  v-model="mobileNumber"
                  type="text"
                  placeholder="Enter your phone number"
                  @input="countLenght"
                  v-on:keypress="NumbersOnly"
                />
              </div>

              <div class="column">
                <div class="text-center btn_Div">
                  <button :disabled="submitDisabled">Next</button>
                </div>
              </div>
            </form>
          </div>

          <div class="mr_top" v-if="isLogin && otpsent">
            <div class="page_titel">
              <h1 v-html="pageTitle"></h1>
            </div>

            <div class="otp_form_main_div">
              <h1>Enter OTP</h1>
              <div class="otp_box">
                <VOtpInput
                  @on-complete="handleOnComplete"
                  input-classes="otp-input"
                  separator=""
                />
              </div>

              <div class="notify_otp_div">
                <span class="span_1" v-if="!resend">
                  You will recieve OTP
                  <span class="span_2">{{ countDown }}s</span></span
                >
                <span class="span_2" v-else @click.prevent="handelresendOTP"
                  >Resend OTP</span
                >
              </div>

              <div class="column">
                <div class="text-center btn_Div">
                  <button
                    @click.prevent="login"
                    :disabled="validated ? false : true"
                  >
                    Confirm OTP
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="mr_top" v-if="userExists === 'no'">
            <div class="page_titel">
              <h1>Signup</h1>
            </div>

            <div class="signup_form_main_div">
              <form class="form" @submit.prevent="handleSignup">
                <div class="column">
                  <label>Full Name</label>
                  <input
                    class="focus:outline-none focus:shadow-outline"
                    v-model="name"
                    type="text"
                    placeholder="Enter your name"
                    minlength="3"
                  />
                </div>

                <div class="column">
                  <label>Email</label>
                  <input
                    class="focus:outline-none focus:shadow-outline"
                    v-model="email"
                    type="email"
                    placeholder="Enter your email id"
                  />
                </div>

                <div class="column_chkbox">
                  <div class="chkbox_div">
                    <input
                      type="checkbox"
                      checked
                      v-model="whatsappcheck"
                      id="alerts_whatsapp"
                      class="regular-checkbox"
                    />
                    <label for="alerts_whatsapp"></label>
                    <label for="alerts_whatsapp"
                      >Get important alerts on whatsapp</label
                    >
                  </div>

                  <div class="chkbox_div">
                    <input
                      type="checkbox"
                      checked
                      id="terms_conditions"
                      class="regular-checkbox"
                      required
                    />
                    <label for="terms_conditions"></label>
                    <label for="terms_conditions"
                      >I agree to all terms and conditions</label
                    >
                  </div>
                </div>

                <div class="column">
                  <div class="text-center btn_Div">
                    <button>Send mobile OTP</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PageLoader
      :isLoading="isLoading"
      :fullPage="fullPage"
      :cancancel="cancancel"
    />
  </section>
</template>

<script>
import axios from 'axios'
import VOtpInput from 'vue3-otp-input'
import PageLoader from './PageLoading.vue'
export default {
  name: 'login',
  components: {
    VOtpInput,
    PageLoader
  },
  data() {
    return {
      mobileNumber: '',
      email: '',
      name: '',
      otpValue: '',
      otpsent: false,
      isLogin: null,
      validated: false,
      countDown: 30,
      resend: false,
      isLoading: false,
      fullPage: true,
      cancancel: false,
      pageTitle: '',
      isSubmitting: false,
      userExists: null,
      whatsappcheck:true
    }
  },
  computed: {
    submitDisabled() {
      return this.isSubmitting
    }
  },
  methods: {
    NumbersOnly(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async handelMobileOTP() {
      this.isLoading = true
      this.isSubmitting = true

      await this.$store
        .dispatch('handleMobileOTP', this.mobileNumber)
        .then((data) => {
          this.validated = false

          if (data) {
            if (data.islogin) {
              this.pageTitle = 'Login'
              this.countDownTimer()
              this.isLogin = true
              this.otpsent = true

              this.$toast.open({
                message: data.msg,
                type: 'Success',
                duration: 5000,
                dismissible: true
              })

              this.isLoading = false
              this.isSubmitting = false
            } else if (!data.islogin) {
              this.isLogin = false
              this.userExists = "no"              
              this.isLoading = false
              this.isSubmitting = false
            } else {
              this.isLogin = true
            }
          } else {
            this.isLoading = false
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.isSubmitting = false
          this.$toast.open({
            message: 'Please enter valid phone number',
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
    },

    async handleSignup() {
      this.isLoading = true
      if (this.name && this.email) {
        await axios
          .post('register', {
            phone: this.mobileNumber,
            name: this.name,
            email: this.email,
            important_alert_wp:this.whatsappcheck
          })
          .then((response) => {
            if (response.data.status == 200) {
              this.isLoading = false;
              this.otpsent = true
              this.isLogin = true
              this.userExists = "yes"
              this.pageTitle = 'Signup'
              this.countDownTimer()
              if (response.data.islogin === null) {
                this.isLogin = true
              }
            }
            if (response.data.status == 200) {
              localStorage.setItem(
                'user-register',
                JSON.stringify(response.data)
              )
            }
          })
          .catch((err) => {
            this.isLoading = false
            this.$toast.open({
              message: err,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          })
      } else {
        //alert('Test')
      }
    },

    handleOnComplete(value) {
      this.validated = true
      this.otpValue = value
    },

    async login() {
      this.isLoading = true

      await this.$store
        .dispatch('login', {
          mobileNumber: this.mobileNumber,
          otpcode: this.otpValue
        })
        .then(async (data) => {
          if (data) {
            this.$toast.open({
              message: data.msg,
              type: 'success',
              duration: 5000,
              dismissible: true
            })

            this.isLoading = false
            this.isLogin = true
            this.$router.push({ name: 'Invest' })
          } else {
            this.isLoading = false
            this.otpValue = ''

            this.$toast.open({
              message: 'Invalid OTP Entered',
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          }
        })
        .catch((err) => {
          this.isLoading = false

          this.$toast.open({
            message: err,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
    },

    async handelresendOTP() {
      this.resend = false
      this.countDown = 30
      this.isLoading = true
      this.countDownTimer()
      const token = localStorage.getItem('Token')

      await axios
        .post(
          'resend-otp',
          {
            phone: this.mobileNumber
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then((response) => {
          this.$toast.open({
            message: 'New OTP Sent',
            type: 'success',
            duration: 5000,
            dismissible: true
          })
          this.isLoading = false
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
          this.isLoading = false
        })
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.resend = true
      }
    }
  }
}
</script>
<style lang="postcss">
:disabled {
  cursor: not-allowed !important;
}

.mr_top {
  @apply -mt-10;
}

.regular-checkbox {
  display: none;
}

.regular-checkbox + label {
  background-color: #373737;
  padding: 10px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  margin-right: 9px;
}

.regular-checkbox:checked + label {
  background-color: #373737;
  color: #99a1a7;
}

.regular-checkbox:checked + label:after {
  content: '' !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAYAAADWm14/AAAAAXNSR0IArs4c6QAAAKVJREFUSIntls0NgzAMhV826QgZgREYgVG6SdmEERihbNARvh5K1RwIBQnbl0TyLfL35J8nS8EvudKgl5QlLUppdGULHgKKmCLh3+gi4awtCYO/BLcoOIKhwRu8wRu8lrgT9LsuZQifiiRPQfaEDxW/zsUfw57DWEn6EWE+cNsVOBoXTXu9Co6rdk6E0Z4fE2FoMv9FGMP3RTjBfyLugnk1qcuv2Devla8pZc/75wAAAABJRU5ErkJggg==');
  font-size: 14px;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  color: #00ffff !important;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.heading {
  @apply flex flex-wrap w-full mb-5 items-center justify-center text-center;

  h1 {
    @apply text-5xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
  }

  span {
    @apply text-5xl font-bold py-4 text-white;
  }
}

.page_titel {
  @apply flex flex-wrap w-full mb-5 items-center justify-center text-center;

  h1 {
    @apply absolute top-[60px] text-5xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
  }
}

@media (min-width: 320px) and (max-width: 552px) {
  .heading {
    @apply flex flex-wrap w-full mb-10 items-center justify-center text-center;

    h1 {
      @apply font-bold text-3xl text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
    }

    span {
      @apply text-3xl text-center mb-2 block font-bold py-0 text-white !important;
    }
  }
}

.heading2 {
  @apply mb-10 text-right;

  h1 {
    @apply text-5xl font-bold py-4 mr-60 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
  }
}

.main_section {
  @apply container py-12;

  .login_main_div {
    @apply container mt-8 px-4 mx-auto;

    .login_inr_div {
      @apply flex flex-wrap -mx-4;

      .login_col1 {
        @apply w-full lg:w-1/2 px-4 mb-10 lg:mb-0;

        .login_img_inr_div {
          @apply relative h-full overflow-hidden max-w-max rounded-md;

          .login_robot {
            @apply absolute left-0 right-0 m-auto top-12;
          }

          /* svg{ @apply z-10 } */
        }
      }

      .login_col2 {
        @apply w-full lg:w-1/2 px-4;

        .form_main_div {
          @apply max-w-screen-lg rounded-[1.5rem] bg-cardbg2 mx-auto px-14 py-36;

          .login_note {
            @apply text-whitergb text-left capitalize px-14;

            span {
              @apply text-transparent font-semibold bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
            }
          }

          .form {
            @apply w-full;

            .column {
              @apply w-full px-14 mb-6 md:mb-0;

              label {
                @apply block capitalize text-white text-xxs mb-2;
              }

              input {
                @apply w-full bg-cardbg3 text-white p-3 rounded-[0.5rem];
              }
            }
          }
        }

        .otp_form_main_div {
          @apply max-w-screen-lg mt-12 rounded-[0.5rem] bg-cardbg2 mx-auto py-28;

          h1 {
            @apply capitalize text-white text-xxs text-center mb-2;
          }

          .otp_box {
            @apply flex flex-row justify-center text-center px-2 mt-5;

            .otp-input {
              @apply m-2 text-white bg-cardbg3 h-10 w-10 text-center rounded-[0.5rem] focus:outline-none;
            }
          }

          .notify_otp_div {
            @apply flex mt-4 justify-center items-center;

            .span_1 {
              @apply text-xxxxs text-clr2;
            }

            .span_2 {
              @apply text-xxxxs text-borderclr ml-1 cursor-pointer;
            }
          }
        }

        .signup_form_main_div {
          @apply relative mt-12 max-w-screen-lg rounded-lg bg-cardbg2 mx-auto p-14 py-10;

          .form {
            @apply w-full;

            .column {
              @apply w-full px-14 mb-6 md:mb-0 my-4;

              label {
                @apply block capitalize text-white text-xxs mb-2;
              }

              input {
                @apply w-full bg-cardbg3 text-white p-3 rounded-[0.5rem];
              }
            }

            .column_chkbox {
              @apply w-full px-14 mb-6 md:mb-0 my-8;

              .chkbox_div {
                @apply flex items-center mb-4;

                input {
                  @apply mr-3 p-3 w-5 h-5 rounded-[0.5rem] bg-cardbg3 border-transparent focus:border-transparent focus:bg-cardbg3 text-cardbg3 focus:outline-none;
                }

                label {
                  @apply block capitalize text-borderclr text-xxxxs;
                }
              }
            }
          }
        }
      }
    }
  }
}

.shadow {
  animation: shdwMoveUpDown 6s ease infinite;
}

.robot {
  animation: MoveUpDown 6s ease infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(-20px);
  }
}

@keyframes shdwMoveUpDown {
  0%,
  100% {
    transform: translateY(-350px);
  }

  50% {
    transform: translateY(-30px);
  }
}
</style>
