<template>
  <!-- apply to raise section -->

    <div class="frd-raise-main">
        <div class="frd_raise_line">
        <div class="apply_main_div">
          <div class="col_1">
            <h1>Shoot for the Stars<br/><span class="span_1"> Raise money</span> <span class="span_2">for your startup</span> </h1>
            <div class="col_2_rep">
            <img class="resp_mruto" alt="hero" src="../assets/image/raise.png">
          </div>
            <div class="btn_Div">
              <a href="#form">
                <button>Apply</button>
              </a>
            </div>
          </div>
          <div class="col_2">
            <img  alt="hero" src="../assets/image/raise.png">
          </div>
          </div>
        </div>
  <!-- apply to raise section end-->

  <!-- point/details section -->

  <div class="p_detils_main_div">
    <!-- <div class="relative lineafter">
    </div>      -->
      <div class="p_detils_inr_div">
        <div class="p_detils_img_div bg_robot_img" >
        <!-- <img  alt="Astronaut" src="../assets/image/Astronaut_raise.png"> -->
        <img src="../assets/image/line.png" alt="" class="frd-img-line">
        <img src="../assets/image/resp_line.png" alt="" class="resp-frd-img-line">
        </div>
        <div class="flex_row">
          <div class="list">
                <p><span>•</span>Get passionate supporters to<br/> back your social impact mission</p>
                <p><span>•</span>Turn your community into your<br/> biggest asset</p>
                <p><span>•</span>Take cheques, big and small</p>
                <p><span>•</span>Be 100% compliant , without any hassle</p>
            </div>
        </div>
      </div>
  </div>
  </div>
  <!-- point/details section end -->
  <!-- apply to raise -->
    <div v-if="!detailsSubmited">
      <!-- heading -->
        <div class="heading" id="form">
            <h1>Apply</h1>
        </div>
      <!-- heading end -->
      <!-- form -->
        <div class="form_main_div">
            <form class="form">
              <div class="flex_wrp">
                <div class="form_flex">
                  <div class="column">
                  <label>Full Name</label>
                  <input class="focus:outline-none focus:shadow-outline" v-model="raiseFullname" type="text" placeholder="Enter your full name">
                  </div>
                  <div class="column">
                  <label>Email</label>
                  <input class="focus:outline-none focus:shadow-outline" v-model="raiseEmail" type="email" placeholder="Enter official email id" >
                  </div>
                  <div class="column">
                  <label>Phone number</label>
                  <input class="focus:outline-none focus:shadow-outline"  type="text" v-model="raisePhone" placeholder="Enter your phone number" >
                  </div>
                  <div class="column">
                  <label>Company name</label>
                  <input class="focus:outline-none focus:shadow-outline"  type="text" v-model="raiseCompanyname" placeholder="Enter registered name of the company" >
                  </div>
                </div>

                <div class="form_flex">
                  <div class="column">
                  <label>Sector</label>
                  <select class="focus:outline-none focus:shadow-outline" v-model="raiseSector">
                    <option value="">Select Sector</option>
                    <option value="Arts & Entertainment">Arts & Entertainment</option>
                    <option value="Energy">Energy</option>
                    <option value="Health & Wellness">Health & Wellness</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Transportation">Transportation</option>
                    <option value="Cannabis">Cannabis</option>
                    <option value="Fashion">Fashion</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Services">Services</option>
                    <option value="Consumer Goods & Retail">Consumer Goods & Retail</option>
                    <option value="Fintech & Finance">Fintech & Finance</option>
                    <option value="Media">Media</option>
                    <option value="Technology">Technology</option>
                    <option value="Others">Others</option>
                  </select>
                  </div>
                  <div class="column">
                  <label>Company website/ app URL</label>
                  <input class="focus:outline-none focus:shadow-outline"  type="url" v-model="raiseWebsite" placeholder="Enter company website">
                  </div>
                  <div class="column">
                  <label>Looking to raise</label>
                    <div class="radio_div">
                      <input id="eqt" type="radio" name="radio" class="radio_btn"  value="Equity" v-model="rasieLooking" >
                      <label for="eqt" class="lbl_nam">Equity</label>
                      <input id="debt" type="radio" name="radio" class="radio_btn" value="Debt" v-model="rasieLooking">
                      <label for="debt" class="lbl_nam">Debt</label>
                      <input id="eith" type="radio" name="radio" class="radio_btn" value="Either" v-model="rasieLooking">
                      <label for="eith" class="lbl_nam">Either</label>
                    </div>
                  </div>
                  <div class="column oth_btn">
                  <div class="btn_Div">
                      <button @click.prevent="handelstartuplead">Submit</button>
                  </div>
                  </div>
                </div>
                <p class="fieldnote">Note:<span> All fields are compulsory</span></p>
              </div>
            </form>
        </div>
    </div>
      <!-- form end -->
      <!-- KYC Complete -->
      <div class="sartup_submit_sucessfully" v-else>
          <div class="sartup_div">
            <img src="../assets/image/rightchek.png" alt="">
            <p>Details submitted sucessfully.</p>
          </div>
          <p>Our team will get in touch with<br/> you as soon as possible</p>
      </div>
      <!-- KYC Complete.end -->
  <!-- apply to raise end -->
</template>

<script>
import axios from 'axios';
export default {
 // -next-line vue/multi-word-component-names
 name:'raise',
  data() {
    return {
      raiseFullname:'',
      raiseEmail:'',
      raisePhone:'',
      raiseCompanyname:'',
      raiseSector:'',
      raiseWebsite:'',
      rasieLooking:'',
      detailsSubmited:false
    };
  },
  computed:{

  },
  methods: {
  async handelstartuplead(){
      await axios.post('startup-lead/store',{
        full_name:this.raiseFullname,
        email:this.raiseEmail,
        phone:this.raisePhone,
        company_name:this.raiseCompanyname,
        sector:this.raiseSector,
        website:this.raiseWebsite,
        looking_to_raise:this.rasieLooking
       })
     .then((response)=>{
        if(response.data.status == 200)
        {
          this.$toast.open({
            message: response.data.msg,
            type: "success",
            duration: 5000,
            dismissible: true
          })

          this.raiseFullname='',
          this.raiseEmail='',
          this.raisePhone='',
          this.raiseCompanyname='',
          this.raiseSector='',
          this.raiseWebsite='',
          this.rasieLooking='',
          this.detailsSubmited=true

        }else{
          this.$toast.open({
            message: response.data.msg,
            type: "error",
            duration: 5000,
            dismissible: true
          })
        }
      })
     }
   }

}
</script>
<style>
/* .bg_img{
  position: relative;
} */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.bg_robot_img::before{
  content: '';
  background-image: url('../assets/image/Astronaut_raise.png');
    background-repeat: no-repeat;
    width: 750px;
    height: 100%;
    -webkit-animation: rotation 10s infinite ease;
    animation: rotation 10s infinite ease;
    margin-left: -95px;
    margin-top: -252px;
    aspect-ratio: 1;
    background-position: bottom;
}

.p_detils_inr_div{
  position: relative;
}
.lineafter::after{
  content: '';
  background-image: url('../assets/image/line.png');
  background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    position: absolute;
    right: 0;
    top: -640px;
    aspect-ratio: 1;
    display: none;
}
input[type='radio']:after {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        top: -5px;
        left: 1px;
        position: absolute;
        background-color: #373737;
        content: '';
        display: inline-block;
        visibility: visible;
        cursor: pointer;
    }
input[type='radio']:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 15px;
    top: -5px;
    left: 1px;
    position: absolute;
    background-color: #3AD2E9;
    content: '';
    display: inline-block;
    visibility: visible;
}

</style>
<style lang="postcss" scoped>
.frd-raise-main{
  @apply relative overflow-hidden;
   .frd-img-line{
      @apply absolute -top-[24rem] -left-4 w-full right-0 -z-[1];
  }
}

.apply_main_div{ @apply container mx-auto flex px-5 pt-24 pb-24 md:flex-row flex-col;
  .col_1{@apply lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col  md:text-left mb-16 md:mb-0 items-center text-center justify-around;
    h1{ @apply font-bold text-4xl whitespace-nowrap text-white;
      .span_1{ @apply  text-4xl whitespace-nowrap;
       }
      .span_2{ @apply  text-4xl whitespace-nowrap font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-gradient to-borderclr;
      }
    }
  }
  .col_2{ @apply lg:max-w-lg lg:w-full md:w-1/2 w-5/6;
    img{ @apply object-cover object-center rounded m-auto;
    }
  }
}
.p_detils_img_div img{
  z-index: 11;

}
.p_detils_main_div{ @apply container px-5 pt-24 mx-auto;
  .p_detils_inr_div { @apply py-8 flex flex-wrap md:flex-nowrap items-center;
    .p_detils_img_div { @apply xl:w-96 md:mb-0 mb-6 flex-shrink-0 flex flex-col;}
    .flex_row{
      .list{ @apply -mt-28 whitespace-nowrap;
        p{ @apply text-[1.7rem] font-medium py-2 leading-[3rem] text-transparent bg-clip-text bg-gradient-to-br from-clr4 to-clr5;
          span{ @apply mr-4;
          }
        }
        br{ @apply xl:hidden;}
      }
    }
  }
}
.heading{
  @apply flex flex-wrap w-full flex-col items-center text-center;
    h1{ @apply text-6xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2;
    }
}
@media (min-width: 320px) and (max-width: 552px){
  .heading{
   @apply flex flex-wrap w-full mb-10 flex-col items-center text-center;
    h1{ @apply text-3xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2 !important;
    }
  }
}
.form_main_div{ @apply max-w-screen-lg rounded-[1.5rem] bg-cardbg2 mx-auto px-8 pb-8 my-20;
  .form{ @apply w-full;
    .flex_wrp{ @apply flex flex-wrap -mx-3 mb-2;
    .form_flex{ @apply w-1/2;
        .column{ @apply w-full px-10 mb-6 md:mb-0 my-8;
          label{ @apply block text-clr3 text-xxs font-medium mb-2;}
          input{ @apply w-full bg-cardbg3 text-white p-3 rounded-[0.5rem];}
          select{
            -webkit-appearance: none !important;
              -moz-appearance: none !important;
              background-color: #373737;
              width: 100%;
              height: 45px;
              width: 100%;
              color:#9ca3af;
              background-image: url(data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.127L12 18.127L20 10.127H4Z" fill="%238E8E93"/></svg>);
              background-position: 98%;
              background-repeat: no-repeat;
              padding: 0.5rem;
              border-radius: 0.5rem;
            }
          .radio_div{ @apply flex items-center justify-start mt-6;
            .radio_btn{ @apply w-4 relative;
            }
            .lbl_nam{ @apply text-white opacity-75 text-base mr-7 m-0 font-thin pl-4 cursor-pointer;
            }
          }
        }
        .oth_btn{
          @apply text-right mt-16;
        }
      }
    }
  }
}
.sartup_submit_sucessfully{ @apply max-w-[1024px]  rounded-[0.5rem] bg-cardbg2 mx-auto p-20 my-40;
  .sartup_div{ @apply flex justify-center mb-10 py-14 items-center;
    p{ @apply text-3xl text-center text-menutext ml-8;
    }
  }
  p{ @apply text-base text-center  text-menutext ml-8;
  }
}
@media (min-width: 320px) and (max-width: 552px){
  input[type='radio']:after {
          width: 20px;
          height: 20px;
          border-radius: 15px;
          top: -3px;
          left: -2px;
          position: absolute;
          background-color: #373737;
          content: '';
          display: inline-block;
          visibility: visible;
          cursor: pointer;
      }
  input[type='radio']:checked:after {
      width: 20px;
      height: 20px;
      border-radius: 15px;
      top: -3px;
      left: -2px;
      position: absolute;
      background-color: #3AD2E9;
      content: '';
      display: inline-block;
      visibility: visible;
  }
}
</style>
