<template>
<div class="">
    <div class="home_main_div bg_img">
        <div class="home_box">
            <div class="col -mr-32">
                <div class="ufo_svg">
                        <svg width="460" class="ufo-main" height="490" viewBox="0 0 1163 1054" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.2" filter="url(#filter0_f_965_28838)">
                                <path d="M963 364.302C963 382.817 792.197 409 581.5 409C370.803 409 200 382.817 200 364.302C200 345.788 370.803 252 581.5 252C792.197 252 963 345.788 963 364.302Z" fill="#00FFFF"/>
                                </g>
                                <path d="M749.628 272.333C749.628 320.149 678.856 358.911 591.554 358.911C504.252 358.911 433.48 320.149 433.48 272.333C433.48 224.518 502.018 184.639 589.32 184.639C676.622 184.639 749.628 224.518 749.628 272.333Z" fill="url(#paint0_radial_965_28838)"/>
                                <path d="M963 341.595C963 387.251 795.548 409.182 584.851 409.182C374.155 409.182 200 386.134 200 340.478C200 294.822 375.272 246.639 585.969 246.639C796.665 246.639 963 295.939 963 341.595Z" fill="url(#paint1_linear_965_28838)" fill-opacity="0.99"/>
                                <ellipse cx="581.5" cy="343.271" rx="381.5" ry="82.6676" fill="url(#paint2_linear_965_28838)"/>
                                <path d="M807.13 373.485C807.52 373.284 807.719 373.08 807.719 372.875C807.719 370.298 802.713 356.812 753.538 346.064C714.416 337.513 637.701 330.982 582.617 330.982C529.094 330.982 450.33 337.827 411.696 346.064C360.595 356.958 357.515 370.24 357.515 372.875C357.515 373.062 357.681 373.249 358.008 373.433H357.515L392.146 422.587H778.673L807.13 373.485Z" fill="url(#paint3_linear_965_28838)" fill-opacity="0.99"/>
                                <ellipse cx="583.176" cy="422.028" rx="192.146" ry="37.4239" fill="url(#paint4_linear_965_28838)"/>
                                <ellipse cx="583.176" cy="422.029" rx="155.281" ry="30.7211" fill="#00FFFF"/>
                                <path d="M724.236 431.081C724.39 430.996 724.467 430.894 724.467 430.774C724.467 430.547 724.643 430.142 724.883 429.594C724.977 429.377 725.081 429.138 725.188 428.879L725.681 427.739L725.616 427.735C727.166 423.021 727.206 414.227 701.197 408.542C673.875 402.571 620.301 398.01 581.833 398.01C544.455 398.01 489.449 402.791 462.47 408.542C432.699 414.889 440.206 425.682 443.513 430.155L448.817 443.774H718.75L724.236 431.081Z" fill="url(#paint5_linear_965_28838)" fill-opacity="0.99"/>
                                <path d="M718.907 443.813C718.907 458.247 657.113 469.607 583.003 469.607C508.894 469.607 448.817 457.906 448.817 443.472C448.817 429.038 508.894 417.336 583.003 417.336C657.113 417.336 718.907 429.379 718.907 443.813Z" fill="url(#paint6_linear_965_28838)"/>
                                <path d="M691.445 443.471C691.445 455.32 642.894 464.926 583.003 464.926C523.113 464.926 474.562 455.32 474.562 443.471C474.562 431.623 523.113 424.263 583.003 424.263C642.894 424.263 691.445 431.623 691.445 443.471Z" fill="#00FFFF"/>
                                <path d="M898.207 358.951C898.207 362.675 888.453 368.805 876.422 368.805C864.391 368.805 854.638 362.675 854.638 358.951C854.638 355.227 864.391 352.208 876.422 352.208C888.453 352.208 898.207 355.227 898.207 358.951Z" fill="url(#paint7_radial_965_28838)"/>
                                <path d="M311.713 358.562C311.713 362.071 302.523 367.848 291.186 367.848C279.849 367.848 270.658 362.071 270.658 358.562C270.658 355.053 279.849 352.208 291.186 352.208C302.523 352.208 311.713 355.053 311.713 358.562Z" fill="url(#paint8_radial_965_28838)"/>
                                <g filter="url(#filter1_f_965_28838)">
                                <path d="M311.713 358.562C311.713 362.071 302.523 367.848 291.186 367.848C279.849 367.848 270.658 362.071 270.658 358.562C270.658 355.053 279.849 360.587 291.186 360.587C302.523 360.587 311.713 355.053 311.713 358.562Z" fill="url(#paint9_radial_965_28838)"/>
                                </g>
                                <path d="M426.777 314.784C426.777 318.794 416.274 325.397 403.318 325.397C390.361 325.397 379.858 318.794 379.858 314.784C379.858 310.773 390.361 307.522 403.318 307.522C416.274 307.522 426.777 310.773 426.777 314.784Z" fill="url(#paint10_radial_965_28838)"/>
                                <g filter="url(#filter2_f_965_28838)">
                                <path d="M424.264 316.508C424.264 320.017 415.074 325.794 403.737 325.794C392.4 325.794 383.209 320.017 383.209 316.508C383.209 312.999 392.4 318.533 403.737 318.533C415.074 318.533 424.264 312.999 424.264 316.508Z" fill="url(#paint11_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter3_f_965_28838)">
                                <path d="M604.122 298.634C604.122 302.143 594.932 307.92 583.595 307.92C572.258 307.92 563.067 302.143 563.067 298.634C563.067 295.125 572.258 300.659 583.595 300.659C594.932 300.659 604.122 295.125 604.122 298.634Z" fill="url(#paint12_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter4_f_965_28838)">
                                <path d="M781.746 316.508C781.746 320.017 772.555 325.794 761.218 325.794C749.881 325.794 740.691 320.017 740.691 316.508C740.691 312.999 749.881 318.533 761.218 318.533C772.555 318.533 781.746 312.999 781.746 316.508Z" fill="url(#paint13_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter5_f_965_28838)">
                                <path d="M896.81 360.076C896.81 363.585 887.62 369.362 876.283 369.362C864.946 369.362 855.755 363.585 855.755 360.076C855.755 356.567 864.946 362.101 876.283 362.101C887.62 362.101 896.81 356.567 896.81 360.076Z" fill="url(#paint14_radial_965_28838)"/>
                                </g>
                                <path d="M606.635 296.91C606.635 300.92 596.132 307.523 583.176 307.523C570.219 307.523 559.716 300.92 559.716 296.91C559.716 292.899 570.219 289.648 583.176 289.648C596.132 289.648 606.635 292.899 606.635 296.91Z" fill="url(#paint15_radial_965_28838)"/>
                                <path d="M784.259 314.784C784.259 318.794 773.756 325.397 760.799 325.397C747.843 325.397 737.34 318.794 737.34 314.784C737.34 310.773 747.843 307.522 760.799 307.522C773.756 307.522 784.259 310.773 784.259 314.784Z" fill="url(#paint16_radial_965_28838)"/>
                                <path class="ufo-laser" d="M475.201 442H691.924L861.728 1053.07H273L475.201 442Z" fill="url(#paint17_linear_965_28838)"/>
                                <path opacity="0.2" d="M639.586 195.965C639.987 195.19 640.839 194.755 641.701 194.896C655.633 197.164 668.581 200.958 679.756 206.05C689.459 210.471 697.642 215.788 703.945 221.75C705.571 223.287 704.8 225.929 702.64 226.51C701.549 226.803 700.391 226.454 699.608 225.64C693.512 219.304 685.195 213.679 675.124 209.09C665.089 204.517 653.521 201.067 641.078 198.93C639.71 198.694 638.949 197.199 639.586 195.965Z" fill="white"/>
                                <path opacity="0.2" d="M709.457 230.954C710.713 230.697 712.011 231.212 712.686 232.302C715.288 236.506 717.008 240.856 717.815 245.268C718.186 247.294 716.559 249.052 714.5 249.092C712.569 249.13 710.972 247.636 710.629 245.735C710.035 242.448 708.899 239.199 707.234 236.025C706.175 234.006 707.224 231.412 709.457 230.954Z" fill="white"/>
                                <path opacity="0.3" d="M306.262 359.469C306.804 359.469 307.256 359.921 307.072 360.432C306.901 360.907 306.602 361.374 306.181 361.821C305.48 362.566 304.451 363.243 303.154 363.814C301.858 364.385 300.318 364.837 298.624 365.146C297.063 365.43 295.399 365.588 293.713 365.611C293.474 365.614 293.28 365.42 293.28 365.181C293.28 364.942 293.474 364.749 293.713 364.745C295.141 364.723 296.55 364.588 297.872 364.347C299.328 364.082 300.65 363.693 301.765 363.203C302.879 362.712 303.763 362.13 304.366 361.49C304.689 361.146 304.928 360.789 305.081 360.425C305.29 359.925 305.719 359.469 306.262 359.469Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M421.326 314.784C421.869 314.784 422.321 315.236 422.136 315.746C421.965 316.222 421.667 316.688 421.246 317.135C420.544 317.881 419.516 318.558 418.219 319.128C416.922 319.699 415.383 320.152 413.689 320.46C412.128 320.745 410.463 320.902 408.777 320.925C408.538 320.928 408.345 320.734 408.345 320.496C408.345 320.257 408.538 320.064 408.777 320.06C410.205 320.037 411.614 319.902 412.936 319.661C414.392 319.396 415.715 319.007 416.829 318.517C417.943 318.027 418.827 317.445 419.43 316.804C419.754 316.461 419.993 316.104 420.145 315.74C420.355 315.239 420.783 314.784 421.326 314.784Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M600.067 298.027C600.61 298.027 601.061 298.479 600.877 298.989C600.706 299.465 600.408 299.931 599.987 300.378C599.285 301.124 598.257 301.801 596.96 302.372C595.663 302.942 594.124 303.395 592.43 303.704C590.869 303.988 589.204 304.145 587.518 304.168C587.279 304.172 587.086 303.978 587.086 303.739C587.086 303.5 587.279 303.307 587.518 303.303C588.946 303.28 590.355 303.146 591.677 302.905C593.133 302.639 594.456 302.25 595.57 301.76C596.684 301.27 597.568 300.688 598.171 300.047C598.495 299.704 598.734 299.347 598.886 298.983C599.096 298.482 599.524 298.027 600.067 298.027Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M777.691 315.901C778.234 315.901 778.685 316.353 778.501 316.864C778.33 317.339 778.031 317.805 777.611 318.252C776.909 318.998 775.88 319.675 774.583 320.246C773.287 320.816 771.747 321.269 770.053 321.578C768.492 321.862 766.828 322.019 765.142 322.042C764.903 322.046 764.709 321.852 764.709 321.613C764.709 321.374 764.903 321.181 765.142 321.177C766.57 321.154 767.979 321.02 769.301 320.779C770.757 320.513 772.08 320.124 773.194 319.634C774.308 319.144 775.192 318.562 775.795 317.921C776.118 317.578 776.357 317.221 776.51 316.857C776.719 316.356 777.148 315.901 777.691 315.901Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M892.755 357.794C893.298 357.794 893.748 358.239 893.629 358.768C893.474 359.46 893.154 360.139 892.675 360.786C891.973 361.735 890.945 362.597 889.648 363.323C888.351 364.049 886.812 364.625 885.118 365.018C883.593 365.372 881.97 365.571 880.324 365.607C880.02 365.614 879.774 365.367 879.774 365.063C879.774 364.759 880.02 364.514 880.324 364.506C881.712 364.47 883.079 364.3 884.366 364.001C885.821 363.664 887.144 363.169 888.258 362.545C889.372 361.921 890.256 361.18 890.859 360.365C891.239 359.851 891.503 359.314 891.646 358.765C891.783 358.24 892.212 357.794 892.755 357.794Z" fill="#C4C4C4"/>
                                <defs>
                                <filter id="filter0_f_965_28838" x="0" y="52" width="1163" height="557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter1_f_965_28838" x="268.424" y="355.162" width="45.523" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter2_f_965_28838" x="380.975" y="313.109" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter3_f_965_28838" x="560.833" y="295.234" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter4_f_965_28838" x="738.457" y="313.109" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter5_f_965_28838" x="853.521" y="356.676" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <radialGradient id="paint0_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(456.381 243.847) rotate(40.4057) scale(177.515 374.303)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <linearGradient id="paint1_linear_965_28838" x1="220.108" y1="367.848" x2="923.342" y2="353.884" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_965_28838" x1="902.116" y1="343.271" x2="238.541" y2="343.271" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear_965_28838" x1="369.38" y1="399.292" x2="784.303" y2="390.666" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint4_linear_965_28838" x1="744.657" y1="422.028" x2="410.441" y2="422.028" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <linearGradient id="paint5_linear_965_28838" x1="447.72" y1="432.137" x2="711.224" y2="425.171" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint6_linear_965_28838" x1="697.355" y1="443.472" x2="462.46" y2="443.472" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <radialGradient id="paint7_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(857.794 357.847) rotate(30.4675) scale(21.6123 40.3501)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint8_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 357.522) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint9_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 357.522) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint10_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(383.257 313.595) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint11_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(386.183 315.468) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint12_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(566.041 297.594) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint13_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(743.665 315.468) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint14_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(858.729 359.036) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint15_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(563.115 295.721) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint16_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(740.738 313.595) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <linearGradient id="paint17_linear_965_28838" x1="567.364" y1="442" x2="567.364" y2="1005.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00FFFF" stop-opacity="0.3"/>
                                <stop offset="1" stop-color="#C4C4C4" stop-opacity="0"/>
                                </linearGradient>
                                </defs>
                        </svg>
                </div>
                <div class="box">
                        <div class="inr_box">
                            <h6>Learn</h6>
                            <p>Join our cosmos and vibe with like minded explorers</p>
                            <router-link to="/howitworks">
                                <button class="btn hovr_btn">Learn</button>
                            </router-link>
                        </div>
                    </div>
            </div>
            <div class="col -mt-44">
                <div class="cntr_ufo_svg">
                        <svg width="741" class="ufo-main" height="832" viewBox="0 0 1163 1054" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.2" filter="url(#filter0_f_965_28838)">
                                <path d="M963 364.302C963 382.817 792.197 409 581.5 409C370.803 409 200 382.817 200 364.302C200 345.788 370.803 252 581.5 252C792.197 252 963 345.788 963 364.302Z" fill="#00FFFF"/>
                                </g>
                                <path d="M749.628 272.333C749.628 320.149 678.856 358.911 591.554 358.911C504.252 358.911 433.48 320.149 433.48 272.333C433.48 224.518 502.018 184.639 589.32 184.639C676.622 184.639 749.628 224.518 749.628 272.333Z" fill="url(#paint0_radial_965_28838)"/>
                                <path d="M963 341.595C963 387.251 795.548 409.182 584.851 409.182C374.155 409.182 200 386.134 200 340.478C200 294.822 375.272 246.639 585.969 246.639C796.665 246.639 963 295.939 963 341.595Z" fill="url(#paint1_linear_965_28838)" fill-opacity="0.99"/>
                                <ellipse cx="581.5" cy="343.271" rx="381.5" ry="82.6676" fill="url(#paint2_linear_965_28838)"/>
                                <path d="M807.13 373.485C807.52 373.284 807.719 373.08 807.719 372.875C807.719 370.298 802.713 356.812 753.538 346.064C714.416 337.513 637.701 330.982 582.617 330.982C529.094 330.982 450.33 337.827 411.696 346.064C360.595 356.958 357.515 370.24 357.515 372.875C357.515 373.062 357.681 373.249 358.008 373.433H357.515L392.146 422.587H778.673L807.13 373.485Z" fill="url(#paint3_linear_965_28838)" fill-opacity="0.99"/>
                                <ellipse cx="583.176" cy="422.028" rx="192.146" ry="37.4239" fill="url(#paint4_linear_965_28838)"/>
                                <ellipse cx="583.176" cy="422.029" rx="155.281" ry="30.7211" fill="#00FFFF"/>
                                <path d="M724.236 431.081C724.39 430.996 724.467 430.894 724.467 430.774C724.467 430.547 724.643 430.142 724.883 429.594C724.977 429.377 725.081 429.138 725.188 428.879L725.681 427.739L725.616 427.735C727.166 423.021 727.206 414.227 701.197 408.542C673.875 402.571 620.301 398.01 581.833 398.01C544.455 398.01 489.449 402.791 462.47 408.542C432.699 414.889 440.206 425.682 443.513 430.155L448.817 443.774H718.75L724.236 431.081Z" fill="url(#paint5_linear_965_28838)" fill-opacity="0.99"/>
                                <path d="M718.907 443.813C718.907 458.247 657.113 469.607 583.003 469.607C508.894 469.607 448.817 457.906 448.817 443.472C448.817 429.038 508.894 417.336 583.003 417.336C657.113 417.336 718.907 429.379 718.907 443.813Z" fill="url(#paint6_linear_965_28838)"/>
                                <path d="M691.445 443.471C691.445 455.32 642.894 464.926 583.003 464.926C523.113 464.926 474.562 455.32 474.562 443.471C474.562 431.623 523.113 424.263 583.003 424.263C642.894 424.263 691.445 431.623 691.445 443.471Z" fill="#00FFFF"/>
                                <path d="M898.207 358.951C898.207 362.675 888.453 368.805 876.422 368.805C864.391 368.805 854.638 362.675 854.638 358.951C854.638 355.227 864.391 352.208 876.422 352.208C888.453 352.208 898.207 355.227 898.207 358.951Z" fill="url(#paint7_radial_965_28838)"/>
                                <path d="M311.713 358.562C311.713 362.071 302.523 367.848 291.186 367.848C279.849 367.848 270.658 362.071 270.658 358.562C270.658 355.053 279.849 352.208 291.186 352.208C302.523 352.208 311.713 355.053 311.713 358.562Z" fill="url(#paint8_radial_965_28838)"/>
                                <g filter="url(#filter1_f_965_28838)">
                                <path d="M311.713 358.562C311.713 362.071 302.523 367.848 291.186 367.848C279.849 367.848 270.658 362.071 270.658 358.562C270.658 355.053 279.849 360.587 291.186 360.587C302.523 360.587 311.713 355.053 311.713 358.562Z" fill="url(#paint9_radial_965_28838)"/>
                                </g>
                                <path d="M426.777 314.784C426.777 318.794 416.274 325.397 403.318 325.397C390.361 325.397 379.858 318.794 379.858 314.784C379.858 310.773 390.361 307.522 403.318 307.522C416.274 307.522 426.777 310.773 426.777 314.784Z" fill="url(#paint10_radial_965_28838)"/>
                                <g filter="url(#filter2_f_965_28838)">
                                <path d="M424.264 316.508C424.264 320.017 415.074 325.794 403.737 325.794C392.4 325.794 383.209 320.017 383.209 316.508C383.209 312.999 392.4 318.533 403.737 318.533C415.074 318.533 424.264 312.999 424.264 316.508Z" fill="url(#paint11_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter3_f_965_28838)">
                                <path d="M604.122 298.634C604.122 302.143 594.932 307.92 583.595 307.92C572.258 307.92 563.067 302.143 563.067 298.634C563.067 295.125 572.258 300.659 583.595 300.659C594.932 300.659 604.122 295.125 604.122 298.634Z" fill="url(#paint12_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter4_f_965_28838)">
                                <path d="M781.746 316.508C781.746 320.017 772.555 325.794 761.218 325.794C749.881 325.794 740.691 320.017 740.691 316.508C740.691 312.999 749.881 318.533 761.218 318.533C772.555 318.533 781.746 312.999 781.746 316.508Z" fill="url(#paint13_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter5_f_965_28838)">
                                <path d="M896.81 360.076C896.81 363.585 887.62 369.362 876.283 369.362C864.946 369.362 855.755 363.585 855.755 360.076C855.755 356.567 864.946 362.101 876.283 362.101C887.62 362.101 896.81 356.567 896.81 360.076Z" fill="url(#paint14_radial_965_28838)"/>
                                </g>
                                <path d="M606.635 296.91C606.635 300.92 596.132 307.523 583.176 307.523C570.219 307.523 559.716 300.92 559.716 296.91C559.716 292.899 570.219 289.648 583.176 289.648C596.132 289.648 606.635 292.899 606.635 296.91Z" fill="url(#paint15_radial_965_28838)"/>
                                <path d="M784.259 314.784C784.259 318.794 773.756 325.397 760.799 325.397C747.843 325.397 737.34 318.794 737.34 314.784C737.34 310.773 747.843 307.522 760.799 307.522C773.756 307.522 784.259 310.773 784.259 314.784Z" fill="url(#paint16_radial_965_28838)"/>
                                <path class="ufo-laser" d="M475.201 442H691.924L861.728 1053.07H273L475.201 442Z" fill="url(#paint17_linear_965_28838)"/>
                                <path opacity="0.2" d="M639.586 195.965C639.987 195.19 640.839 194.755 641.701 194.896C655.633 197.164 668.581 200.958 679.756 206.05C689.459 210.471 697.642 215.788 703.945 221.75C705.571 223.287 704.8 225.929 702.64 226.51C701.549 226.803 700.391 226.454 699.608 225.64C693.512 219.304 685.195 213.679 675.124 209.09C665.089 204.517 653.521 201.067 641.078 198.93C639.71 198.694 638.949 197.199 639.586 195.965Z" fill="white"/>
                                <path opacity="0.2" d="M709.457 230.954C710.713 230.697 712.011 231.212 712.686 232.302C715.288 236.506 717.008 240.856 717.815 245.268C718.186 247.294 716.559 249.052 714.5 249.092C712.569 249.13 710.972 247.636 710.629 245.735C710.035 242.448 708.899 239.199 707.234 236.025C706.175 234.006 707.224 231.412 709.457 230.954Z" fill="white"/>
                                <path opacity="0.3" d="M306.262 359.469C306.804 359.469 307.256 359.921 307.072 360.432C306.901 360.907 306.602 361.374 306.181 361.821C305.48 362.566 304.451 363.243 303.154 363.814C301.858 364.385 300.318 364.837 298.624 365.146C297.063 365.43 295.399 365.588 293.713 365.611C293.474 365.614 293.28 365.42 293.28 365.181C293.28 364.942 293.474 364.749 293.713 364.745C295.141 364.723 296.55 364.588 297.872 364.347C299.328 364.082 300.65 363.693 301.765 363.203C302.879 362.712 303.763 362.13 304.366 361.49C304.689 361.146 304.928 360.789 305.081 360.425C305.29 359.925 305.719 359.469 306.262 359.469Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M421.326 314.784C421.869 314.784 422.321 315.236 422.136 315.746C421.965 316.222 421.667 316.688 421.246 317.135C420.544 317.881 419.516 318.558 418.219 319.128C416.922 319.699 415.383 320.152 413.689 320.46C412.128 320.745 410.463 320.902 408.777 320.925C408.538 320.928 408.345 320.734 408.345 320.496C408.345 320.257 408.538 320.064 408.777 320.06C410.205 320.037 411.614 319.902 412.936 319.661C414.392 319.396 415.715 319.007 416.829 318.517C417.943 318.027 418.827 317.445 419.43 316.804C419.754 316.461 419.993 316.104 420.145 315.74C420.355 315.239 420.783 314.784 421.326 314.784Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M600.067 298.027C600.61 298.027 601.061 298.479 600.877 298.989C600.706 299.465 600.408 299.931 599.987 300.378C599.285 301.124 598.257 301.801 596.96 302.372C595.663 302.942 594.124 303.395 592.43 303.704C590.869 303.988 589.204 304.145 587.518 304.168C587.279 304.172 587.086 303.978 587.086 303.739C587.086 303.5 587.279 303.307 587.518 303.303C588.946 303.28 590.355 303.146 591.677 302.905C593.133 302.639 594.456 302.25 595.57 301.76C596.684 301.27 597.568 300.688 598.171 300.047C598.495 299.704 598.734 299.347 598.886 298.983C599.096 298.482 599.524 298.027 600.067 298.027Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M777.691 315.901C778.234 315.901 778.685 316.353 778.501 316.864C778.33 317.339 778.031 317.805 777.611 318.252C776.909 318.998 775.88 319.675 774.583 320.246C773.287 320.816 771.747 321.269 770.053 321.578C768.492 321.862 766.828 322.019 765.142 322.042C764.903 322.046 764.709 321.852 764.709 321.613C764.709 321.374 764.903 321.181 765.142 321.177C766.57 321.154 767.979 321.02 769.301 320.779C770.757 320.513 772.08 320.124 773.194 319.634C774.308 319.144 775.192 318.562 775.795 317.921C776.118 317.578 776.357 317.221 776.51 316.857C776.719 316.356 777.148 315.901 777.691 315.901Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M892.755 357.794C893.298 357.794 893.748 358.239 893.629 358.768C893.474 359.46 893.154 360.139 892.675 360.786C891.973 361.735 890.945 362.597 889.648 363.323C888.351 364.049 886.812 364.625 885.118 365.018C883.593 365.372 881.97 365.571 880.324 365.607C880.02 365.614 879.774 365.367 879.774 365.063C879.774 364.759 880.02 364.514 880.324 364.506C881.712 364.47 883.079 364.3 884.366 364.001C885.821 363.664 887.144 363.169 888.258 362.545C889.372 361.921 890.256 361.18 890.859 360.365C891.239 359.851 891.503 359.314 891.646 358.765C891.783 358.24 892.212 357.794 892.755 357.794Z" fill="#C4C4C4"/>
                                <defs>
                                <filter id="filter0_f_965_28838" x="0" y="52" width="1163" height="557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter1_f_965_28838" x="268.424" y="355.162" width="45.523" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter2_f_965_28838" x="380.975" y="313.109" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter3_f_965_28838" x="560.833" y="295.234" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter4_f_965_28838" x="738.457" y="313.109" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter5_f_965_28838" x="853.521" y="356.676" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <radialGradient id="paint0_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(456.381 243.847) rotate(40.4057) scale(177.515 374.303)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <linearGradient id="paint1_linear_965_28838" x1="220.108" y1="367.848" x2="923.342" y2="353.884" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_965_28838" x1="902.116" y1="343.271" x2="238.541" y2="343.271" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear_965_28838" x1="369.38" y1="399.292" x2="784.303" y2="390.666" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint4_linear_965_28838" x1="744.657" y1="422.028" x2="410.441" y2="422.028" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <linearGradient id="paint5_linear_965_28838" x1="447.72" y1="432.137" x2="711.224" y2="425.171" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint6_linear_965_28838" x1="697.355" y1="443.472" x2="462.46" y2="443.472" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <radialGradient id="paint7_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(857.794 357.847) rotate(30.4675) scale(21.6123 40.3501)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint8_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 357.522) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint9_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 357.522) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint10_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(383.257 313.595) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint11_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(386.183 315.468) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint12_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(566.041 297.594) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint13_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(743.665 315.468) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint14_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(858.729 359.036) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint15_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(563.115 295.721) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint16_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(740.738 313.595) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <linearGradient id="paint17_linear_965_28838" x1="567.364" y1="442" x2="567.364" y2="1005.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00FFFF" stop-opacity="0.3"/>
                                <stop offset="1" stop-color="#C4C4C4" stop-opacity="0"/>
                                </linearGradient>
                                </defs>
                        </svg>
                </div>
                <div class="cntr_box">
                        <div class="cntr_inr_box">
                            <div class="cntr_div_text">
                                <h6>Empower</h6>
                                <p>Empower impact first businesses and make a lasting, positive change in the worl</p>
                                <router-link to="/invest">
                                    <button class="cntr_btn hovr_btn">Join now</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="col -ml-32">
                <div class="ufo_svg">
                        <svg width="460" class="ufo-main" height="490" viewBox="0 0 1163 1054" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.2" filter="url(#filter0_f_965_28838)">
                                <path d="M963 364.302C963 382.817 792.197 409 581.5 409C370.803 409 200 382.817 200 364.302C200 345.788 370.803 252 581.5 252C792.197 252 963 345.788 963 364.302Z" fill="#00FFFF"/>
                                </g>
                                <path d="M749.628 272.333C749.628 320.149 678.856 358.911 591.554 358.911C504.252 358.911 433.48 320.149 433.48 272.333C433.48 224.518 502.018 184.639 589.32 184.639C676.622 184.639 749.628 224.518 749.628 272.333Z" fill="url(#paint0_radial_965_28838)"/>
                                <path d="M963 341.595C963 387.251 795.548 409.182 584.851 409.182C374.155 409.182 200 386.134 200 340.478C200 294.822 375.272 246.639 585.969 246.639C796.665 246.639 963 295.939 963 341.595Z" fill="url(#paint1_linear_965_28838)" fill-opacity="0.99"/>
                                <ellipse cx="581.5" cy="343.271" rx="381.5" ry="82.6676" fill="url(#paint2_linear_965_28838)"/>
                                <path d="M807.13 373.485C807.52 373.284 807.719 373.08 807.719 372.875C807.719 370.298 802.713 356.812 753.538 346.064C714.416 337.513 637.701 330.982 582.617 330.982C529.094 330.982 450.33 337.827 411.696 346.064C360.595 356.958 357.515 370.24 357.515 372.875C357.515 373.062 357.681 373.249 358.008 373.433H357.515L392.146 422.587H778.673L807.13 373.485Z" fill="url(#paint3_linear_965_28838)" fill-opacity="0.99"/>
                                <ellipse cx="583.176" cy="422.028" rx="192.146" ry="37.4239" fill="url(#paint4_linear_965_28838)"/>
                                <ellipse cx="583.176" cy="422.029" rx="155.281" ry="30.7211" fill="#00FFFF"/>
                                <path d="M724.236 431.081C724.39 430.996 724.467 430.894 724.467 430.774C724.467 430.547 724.643 430.142 724.883 429.594C724.977 429.377 725.081 429.138 725.188 428.879L725.681 427.739L725.616 427.735C727.166 423.021 727.206 414.227 701.197 408.542C673.875 402.571 620.301 398.01 581.833 398.01C544.455 398.01 489.449 402.791 462.47 408.542C432.699 414.889 440.206 425.682 443.513 430.155L448.817 443.774H718.75L724.236 431.081Z" fill="url(#paint5_linear_965_28838)" fill-opacity="0.99"/>
                                <path d="M718.907 443.813C718.907 458.247 657.113 469.607 583.003 469.607C508.894 469.607 448.817 457.906 448.817 443.472C448.817 429.038 508.894 417.336 583.003 417.336C657.113 417.336 718.907 429.379 718.907 443.813Z" fill="url(#paint6_linear_965_28838)"/>
                                <path d="M691.445 443.471C691.445 455.32 642.894 464.926 583.003 464.926C523.113 464.926 474.562 455.32 474.562 443.471C474.562 431.623 523.113 424.263 583.003 424.263C642.894 424.263 691.445 431.623 691.445 443.471Z" fill="#00FFFF"/>
                                <path d="M898.207 358.951C898.207 362.675 888.453 368.805 876.422 368.805C864.391 368.805 854.638 362.675 854.638 358.951C854.638 355.227 864.391 352.208 876.422 352.208C888.453 352.208 898.207 355.227 898.207 358.951Z" fill="url(#paint7_radial_965_28838)"/>
                                <path d="M311.713 358.562C311.713 362.071 302.523 367.848 291.186 367.848C279.849 367.848 270.658 362.071 270.658 358.562C270.658 355.053 279.849 352.208 291.186 352.208C302.523 352.208 311.713 355.053 311.713 358.562Z" fill="url(#paint8_radial_965_28838)"/>
                                <g filter="url(#filter1_f_965_28838)">
                                <path d="M311.713 358.562C311.713 362.071 302.523 367.848 291.186 367.848C279.849 367.848 270.658 362.071 270.658 358.562C270.658 355.053 279.849 360.587 291.186 360.587C302.523 360.587 311.713 355.053 311.713 358.562Z" fill="url(#paint9_radial_965_28838)"/>
                                </g>
                                <path d="M426.777 314.784C426.777 318.794 416.274 325.397 403.318 325.397C390.361 325.397 379.858 318.794 379.858 314.784C379.858 310.773 390.361 307.522 403.318 307.522C416.274 307.522 426.777 310.773 426.777 314.784Z" fill="url(#paint10_radial_965_28838)"/>
                                <g filter="url(#filter2_f_965_28838)">
                                <path d="M424.264 316.508C424.264 320.017 415.074 325.794 403.737 325.794C392.4 325.794 383.209 320.017 383.209 316.508C383.209 312.999 392.4 318.533 403.737 318.533C415.074 318.533 424.264 312.999 424.264 316.508Z" fill="url(#paint11_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter3_f_965_28838)">
                                <path d="M604.122 298.634C604.122 302.143 594.932 307.92 583.595 307.92C572.258 307.92 563.067 302.143 563.067 298.634C563.067 295.125 572.258 300.659 583.595 300.659C594.932 300.659 604.122 295.125 604.122 298.634Z" fill="url(#paint12_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter4_f_965_28838)">
                                <path d="M781.746 316.508C781.746 320.017 772.555 325.794 761.218 325.794C749.881 325.794 740.691 320.017 740.691 316.508C740.691 312.999 749.881 318.533 761.218 318.533C772.555 318.533 781.746 312.999 781.746 316.508Z" fill="url(#paint13_radial_965_28838)"/>
                                </g>
                                <g filter="url(#filter5_f_965_28838)">
                                <path d="M896.81 360.076C896.81 363.585 887.62 369.362 876.283 369.362C864.946 369.362 855.755 363.585 855.755 360.076C855.755 356.567 864.946 362.101 876.283 362.101C887.62 362.101 896.81 356.567 896.81 360.076Z" fill="url(#paint14_radial_965_28838)"/>
                                </g>
                                <path d="M606.635 296.91C606.635 300.92 596.132 307.523 583.176 307.523C570.219 307.523 559.716 300.92 559.716 296.91C559.716 292.899 570.219 289.648 583.176 289.648C596.132 289.648 606.635 292.899 606.635 296.91Z" fill="url(#paint15_radial_965_28838)"/>
                                <path d="M784.259 314.784C784.259 318.794 773.756 325.397 760.799 325.397C747.843 325.397 737.34 318.794 737.34 314.784C737.34 310.773 747.843 307.522 760.799 307.522C773.756 307.522 784.259 310.773 784.259 314.784Z" fill="url(#paint16_radial_965_28838)"/>
                                <path class="ufo-laser" d="M475.201 442H691.924L861.728 1053.07H273L475.201 442Z" fill="url(#paint17_linear_965_28838)"/>
                                <path opacity="0.2" d="M639.586 195.965C639.987 195.19 640.839 194.755 641.701 194.896C655.633 197.164 668.581 200.958 679.756 206.05C689.459 210.471 697.642 215.788 703.945 221.75C705.571 223.287 704.8 225.929 702.64 226.51C701.549 226.803 700.391 226.454 699.608 225.64C693.512 219.304 685.195 213.679 675.124 209.09C665.089 204.517 653.521 201.067 641.078 198.93C639.71 198.694 638.949 197.199 639.586 195.965Z" fill="white"/>
                                <path opacity="0.2" d="M709.457 230.954C710.713 230.697 712.011 231.212 712.686 232.302C715.288 236.506 717.008 240.856 717.815 245.268C718.186 247.294 716.559 249.052 714.5 249.092C712.569 249.13 710.972 247.636 710.629 245.735C710.035 242.448 708.899 239.199 707.234 236.025C706.175 234.006 707.224 231.412 709.457 230.954Z" fill="white"/>
                                <path opacity="0.3" d="M306.262 359.469C306.804 359.469 307.256 359.921 307.072 360.432C306.901 360.907 306.602 361.374 306.181 361.821C305.48 362.566 304.451 363.243 303.154 363.814C301.858 364.385 300.318 364.837 298.624 365.146C297.063 365.43 295.399 365.588 293.713 365.611C293.474 365.614 293.28 365.42 293.28 365.181C293.28 364.942 293.474 364.749 293.713 364.745C295.141 364.723 296.55 364.588 297.872 364.347C299.328 364.082 300.65 363.693 301.765 363.203C302.879 362.712 303.763 362.13 304.366 361.49C304.689 361.146 304.928 360.789 305.081 360.425C305.29 359.925 305.719 359.469 306.262 359.469Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M421.326 314.784C421.869 314.784 422.321 315.236 422.136 315.746C421.965 316.222 421.667 316.688 421.246 317.135C420.544 317.881 419.516 318.558 418.219 319.128C416.922 319.699 415.383 320.152 413.689 320.46C412.128 320.745 410.463 320.902 408.777 320.925C408.538 320.928 408.345 320.734 408.345 320.496C408.345 320.257 408.538 320.064 408.777 320.06C410.205 320.037 411.614 319.902 412.936 319.661C414.392 319.396 415.715 319.007 416.829 318.517C417.943 318.027 418.827 317.445 419.43 316.804C419.754 316.461 419.993 316.104 420.145 315.74C420.355 315.239 420.783 314.784 421.326 314.784Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M600.067 298.027C600.61 298.027 601.061 298.479 600.877 298.989C600.706 299.465 600.408 299.931 599.987 300.378C599.285 301.124 598.257 301.801 596.96 302.372C595.663 302.942 594.124 303.395 592.43 303.704C590.869 303.988 589.204 304.145 587.518 304.168C587.279 304.172 587.086 303.978 587.086 303.739C587.086 303.5 587.279 303.307 587.518 303.303C588.946 303.28 590.355 303.146 591.677 302.905C593.133 302.639 594.456 302.25 595.57 301.76C596.684 301.27 597.568 300.688 598.171 300.047C598.495 299.704 598.734 299.347 598.886 298.983C599.096 298.482 599.524 298.027 600.067 298.027Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M777.691 315.901C778.234 315.901 778.685 316.353 778.501 316.864C778.33 317.339 778.031 317.805 777.611 318.252C776.909 318.998 775.88 319.675 774.583 320.246C773.287 320.816 771.747 321.269 770.053 321.578C768.492 321.862 766.828 322.019 765.142 322.042C764.903 322.046 764.709 321.852 764.709 321.613C764.709 321.374 764.903 321.181 765.142 321.177C766.57 321.154 767.979 321.02 769.301 320.779C770.757 320.513 772.08 320.124 773.194 319.634C774.308 319.144 775.192 318.562 775.795 317.921C776.118 317.578 776.357 317.221 776.51 316.857C776.719 316.356 777.148 315.901 777.691 315.901Z" fill="#C4C4C4"/>
                                <path opacity="0.3" d="M892.755 357.794C893.298 357.794 893.748 358.239 893.629 358.768C893.474 359.46 893.154 360.139 892.675 360.786C891.973 361.735 890.945 362.597 889.648 363.323C888.351 364.049 886.812 364.625 885.118 365.018C883.593 365.372 881.97 365.571 880.324 365.607C880.02 365.614 879.774 365.367 879.774 365.063C879.774 364.759 880.02 364.514 880.324 364.506C881.712 364.47 883.079 364.3 884.366 364.001C885.821 363.664 887.144 363.169 888.258 362.545C889.372 361.921 890.256 361.18 890.859 360.365C891.239 359.851 891.503 359.314 891.646 358.765C891.783 358.24 892.212 357.794 892.755 357.794Z" fill="#C4C4C4"/>
                                <defs>
                                <filter id="filter0_f_965_28838" x="0" y="52" width="1163" height="557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter1_f_965_28838" x="268.424" y="355.162" width="45.523" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter2_f_965_28838" x="380.975" y="313.109" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter3_f_965_28838" x="560.833" y="295.234" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter4_f_965_28838" x="738.457" y="313.109" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <filter id="filter5_f_965_28838" x="853.521" y="356.676" width="45.5231" height="14.9202" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_965_28838"/>
                                </filter>
                                <radialGradient id="paint0_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(456.381 243.847) rotate(40.4057) scale(177.515 374.303)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <linearGradient id="paint1_linear_965_28838" x1="220.108" y1="367.848" x2="923.342" y2="353.884" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_965_28838" x1="902.116" y1="343.271" x2="238.541" y2="343.271" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear_965_28838" x1="369.38" y1="399.292" x2="784.303" y2="390.666" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint4_linear_965_28838" x1="744.657" y1="422.028" x2="410.441" y2="422.028" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <linearGradient id="paint5_linear_965_28838" x1="447.72" y1="432.137" x2="711.224" y2="425.171" gradientUnits="userSpaceOnUse">
                                <stop offset="0.00833321" stop-color="#024848"/>
                                <stop offset="1"/>
                                </linearGradient>
                                <linearGradient id="paint6_linear_965_28838" x1="697.355" y1="443.472" x2="462.46" y2="443.472" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00110F"/>
                                <stop offset="1" stop-color="#0C2421"/>
                                </linearGradient>
                                <radialGradient id="paint7_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(857.794 357.847) rotate(30.4675) scale(21.6123 40.3501)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint8_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 357.522) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint9_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 357.522) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint10_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(383.257 313.595) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint11_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(386.183 315.468) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint12_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(566.041 297.594) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint13_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(743.665 315.468) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint14_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(858.729 359.036) rotate(30.4675) scale(20.3655 38.0222)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint15_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(563.115 295.721) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <radialGradient id="paint16_radial_965_28838" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(740.738 313.595) rotate(30.4675) scale(23.2748 43.454)">
                                <stop stop-color="#67FFFF"/>
                                <stop offset="1" stop-color="#00ADAD"/>
                                </radialGradient>
                                <linearGradient id="paint17_linear_965_28838" x1="567.364" y1="442" x2="567.364" y2="1005.5" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#00FFFF" stop-opacity="0.3"/>
                                <stop offset="1" stop-color="#C4C4C4" stop-opacity="0"/>
                                </linearGradient>
                                </defs>
                        </svg>
                </div>
                    <div class="box">
                        <div class="inr_box">
                            <h6>Raise</h6>
                            <p>Fuel your rocketship,<br/>leverage the power of your community.</p>
                            <router-link to="/raise">
                                <button class="btn hovr_btn">Raise</button>
                            </router-link>
                        </div>
                    </div>
            </div>
        </div>
    </div>

    <!-- responsive -->
    <div class="resp_main_div">
        <div class="resp_col -mt-44">
            <div class="resp_cntr_ufo_svg">
                <svg xmlns="http://www.w3.org/2000/svg" class="ufo-main" width="480" height="832" viewBox="0 0 1163 1002" fill="none">
                    <g opacity="0.2" filter="url(#filter0_f_804_4074)">
                    <path opacity="0.2" d="M963 312.663C963 331.178 792.197 357.361 581.5 357.361C370.803 357.361 200 331.178 200 312.663C200 294.149 370.803 200.361 581.5 200.361C792.197 200.361 963 294.149 963 312.663Z" fill="#00FFFF"/>
                    </g>
                    <path d="M749.628 220.694C749.628 268.51 678.856 307.272 591.554 307.272C504.252 307.272 433.48 268.51 433.48 220.694C433.48 172.879 502.018 133 589.32 133C676.622 133 749.628 172.879 749.628 220.694Z" fill="url(#paint0_radial_804_4074)"/>
                    <path d="M963 289.956C963 335.612 795.548 357.543 584.851 357.543C374.155 357.543 200 334.495 200 288.839C200 243.183 375.272 195 585.969 195C796.665 195 963 244.3 963 289.956Z" fill="url(#paint1_linear_804_4074)" fill-opacity="0.99"/>
                    <path d="M581.5 374.3C792.197 374.3 963 337.288 963 291.632C963 245.976 792.197 208.965 581.5 208.965C370.803 208.965 200 245.976 200 291.632C200 337.288 370.803 374.3 581.5 374.3Z" fill="url(#paint2_linear_804_4074)"/>
                    <path d="M807.131 321.846C807.521 321.645 807.72 321.441 807.72 321.236C807.72 318.659 802.714 305.173 753.539 294.425C714.417 285.874 637.702 279.343 582.618 279.343C529.095 279.343 450.331 286.188 411.697 294.425C360.596 305.319 357.516 318.601 357.516 321.236C357.516 321.423 357.682 321.61 358.009 321.794H357.516L392.147 370.948H778.674L807.131 321.846Z" fill="url(#paint3_linear_804_4074)" fill-opacity="0.99"/>
                    <path d="M583.175 407.813C689.295 407.813 775.321 391.058 775.321 370.389C775.321 349.72 689.295 332.965 583.175 332.965C477.056 332.965 391.029 349.72 391.029 370.389C391.029 391.058 477.056 407.813 583.175 407.813Z" fill="url(#paint4_linear_804_4074)"/>
                    <path d="M583.176 401.111C668.935 401.111 738.457 387.357 738.457 370.39C738.457 353.423 668.935 339.669 583.176 339.669C497.416 339.669 427.895 353.423 427.895 370.39C427.895 387.357 497.416 401.111 583.176 401.111Z" fill="#00FFFF"/>
                    <path d="M724.236 379.442C724.39 379.357 724.467 379.255 724.467 379.135C724.467 378.908 724.643 378.503 724.883 377.955C724.977 377.738 725.081 377.499 725.188 377.24L725.681 376.1L725.616 376.096C727.166 371.382 727.206 362.588 701.197 356.903C673.875 350.932 620.301 346.371 581.833 346.371C544.455 346.371 489.449 351.152 462.47 356.903C432.699 363.25 440.206 374.043 443.513 378.516L448.817 392.135H718.75L724.236 379.442Z" fill="url(#paint5_linear_804_4074)" fill-opacity="0.99"/>
                    <path d="M718.906 392.174C718.906 406.608 657.112 417.968 583.002 417.968C508.893 417.968 448.816 406.267 448.816 391.833C448.816 377.399 508.893 365.697 583.002 365.697C657.112 365.697 718.906 377.74 718.906 392.174Z" fill="url(#paint6_linear_804_4074)"/>
                    <path d="M691.445 391.832C691.445 403.681 642.894 413.287 583.003 413.287C523.113 413.287 474.562 403.681 474.562 391.832C474.562 379.984 523.113 372.624 583.003 372.624C642.894 372.624 691.445 379.984 691.445 391.832Z" fill="#00FFFF"/>
                    <path d="M898.208 307.312C898.208 311.036 888.454 317.166 876.423 317.166C864.392 317.166 854.639 311.036 854.639 307.312C854.639 303.588 864.392 300.569 876.423 300.569C888.454 300.569 898.208 303.588 898.208 307.312Z" fill="url(#paint7_radial_804_4074)"/>
                    <path d="M311.713 306.923C311.713 310.432 302.523 316.209 291.186 316.209C279.849 316.209 270.658 310.432 270.658 306.923C270.658 303.414 279.849 300.569 291.186 300.569C302.523 300.569 311.713 303.414 311.713 306.923Z" fill="url(#paint8_radial_804_4074)"/>
                    <g filter="url(#filter1_f_804_4074)">
                    <path d="M311.713 306.923C311.713 310.432 302.523 316.209 291.186 316.209C279.849 316.209 270.658 310.432 270.658 306.923C270.658 303.414 279.849 308.948 291.186 308.948C302.523 308.948 311.713 303.414 311.713 306.923Z" fill="url(#paint9_radial_804_4074)"/>
                    </g>
                    <path d="M426.776 263.145C426.776 267.155 416.273 273.758 403.317 273.758C390.36 273.758 379.857 267.155 379.857 263.145C379.857 259.134 390.36 255.883 403.317 255.883C416.273 255.883 426.776 259.134 426.776 263.145Z" fill="url(#paint10_radial_804_4074)"/>
                    <g filter="url(#filter2_f_804_4074)">
                    <path d="M424.264 264.869C424.264 268.378 415.074 274.155 403.737 274.155C392.4 274.155 383.209 268.378 383.209 264.869C383.209 261.36 392.4 266.894 403.737 266.894C415.074 266.894 424.264 261.36 424.264 264.869Z" fill="url(#paint11_radial_804_4074)"/>
                    </g>
                    <g filter="url(#filter3_f_804_4074)">
                    <path d="M604.121 246.995C604.121 250.504 594.931 256.281 583.594 256.281C572.257 256.281 563.066 250.504 563.066 246.995C563.066 243.486 572.257 249.02 583.594 249.02C594.931 249.02 604.121 243.486 604.121 246.995Z" fill="url(#paint12_radial_804_4074)"/>
                    </g>
                    <g filter="url(#filter4_f_804_4074)">
                    <path d="M781.746 264.869C781.746 268.378 772.555 274.155 761.218 274.155C749.881 274.155 740.691 268.378 740.691 264.869C740.691 261.36 749.881 266.894 761.218 266.894C772.555 266.894 781.746 261.36 781.746 264.869Z" fill="url(#paint13_radial_804_4074)"/>
                    </g>
                    <g filter="url(#filter5_f_804_4074)">
                    <path d="M896.811 308.437C896.811 311.946 887.621 317.723 876.284 317.723C864.947 317.723 855.756 311.946 855.756 308.437C855.756 304.928 864.947 310.462 876.284 310.462C887.621 310.462 896.811 304.928 896.811 308.437Z" fill="url(#paint14_radial_804_4074)"/>
                    </g>
                    <path d="M606.636 245.271C606.636 249.281 596.133 255.884 583.177 255.884C570.22 255.884 559.717 249.281 559.717 245.271C559.717 241.26 570.22 238.009 583.177 238.009C596.133 238.009 606.636 241.26 606.636 245.271Z" fill="url(#paint15_radial_804_4074)"/>
                    <path d="M784.259 263.145C784.259 267.155 773.756 273.758 760.799 273.758C747.843 273.758 737.34 267.155 737.34 263.145C737.34 259.134 747.843 255.883 760.799 255.883C773.756 255.883 784.259 259.134 784.259 263.145Z" fill="url(#paint16_radial_804_4074)"/>
                    <path class="ufo-laser" d="M475.201 390.361H691.924L861.728 1001.43H273L475.201 390.361Z" fill="url(#paint17_linear_804_4074)"/>
                    <path opacity="0.2" d="M639.586 144.326C639.987 143.551 640.838 143.116 641.7 143.257C655.632 145.525 668.58 149.319 679.755 154.411C689.458 158.832 697.642 164.149 703.945 170.111C705.571 171.648 704.8 174.29 702.64 174.871C701.549 175.164 700.39 174.815 699.607 174.001C693.511 167.665 685.195 162.04 675.124 157.451C665.089 152.878 653.521 149.428 641.078 147.291C639.71 147.055 638.949 145.56 639.586 144.326Z" fill="white"/>
                    <path opacity="0.2" d="M709.456 179.315C710.712 179.058 712.01 179.573 712.685 180.663C715.287 184.867 717.007 189.217 717.814 193.629C718.185 195.655 716.558 197.413 714.499 197.453C712.568 197.491 710.971 195.997 710.628 194.096C710.034 190.809 708.898 187.56 707.233 184.386C706.174 182.367 707.223 179.773 709.456 179.315Z" fill="white"/>
                    <path opacity="0.3" d="M306.261 307.83C306.803 307.83 307.255 308.282 307.071 308.793C306.9 309.268 306.601 309.735 306.18 310.182C305.479 310.927 304.45 311.604 303.153 312.175C301.857 312.746 300.317 313.198 298.623 313.507C297.062 313.791 295.398 313.949 293.712 313.972C293.473 313.975 293.279 313.781 293.279 313.542C293.279 313.303 293.473 313.11 293.712 313.106C295.14 313.084 296.549 312.949 297.871 312.708C299.327 312.443 300.649 312.054 301.764 311.564C302.878 311.073 303.762 310.491 304.365 309.851C304.688 309.507 304.927 309.15 305.08 308.786C305.289 308.286 305.718 307.83 306.261 307.83Z" fill="#C4C4C4"/>
                    <path opacity="0.3" d="M421.327 263.145C421.87 263.145 422.322 263.597 422.137 264.107C421.966 264.583 421.668 265.049 421.247 265.496C420.545 266.242 419.517 266.919 418.22 267.489C416.923 268.06 415.384 268.513 413.69 268.821C412.129 269.106 410.464 269.263 408.778 269.286C408.539 269.289 408.346 269.095 408.346 268.857C408.346 268.618 408.539 268.425 408.778 268.421C410.206 268.398 411.615 268.263 412.937 268.022C414.393 267.757 415.716 267.368 416.83 266.878C417.944 266.388 418.828 265.806 419.431 265.165C419.755 264.822 419.994 264.465 420.146 264.101C420.356 263.6 420.784 263.145 421.327 263.145Z" fill="#C4C4C4"/>
                    <path opacity="0.3" d="M600.067 246.388C600.61 246.388 601.061 246.84 600.877 247.35C600.706 247.826 600.408 248.292 599.987 248.739C599.285 249.485 598.257 250.162 596.96 250.733C595.663 251.303 594.124 251.756 592.43 252.065C590.869 252.349 589.204 252.506 587.518 252.529C587.279 252.533 587.086 252.339 587.086 252.1C587.086 251.861 587.279 251.668 587.518 251.664C588.946 251.641 590.355 251.507 591.677 251.266C593.133 251 594.456 250.611 595.57 250.121C596.684 249.631 597.568 249.049 598.171 248.408C598.495 248.065 598.734 247.708 598.886 247.344C599.096 246.843 599.524 246.388 600.067 246.388Z" fill="#C4C4C4"/>
                    <path opacity="0.3" d="M777.691 264.262C778.234 264.262 778.685 264.714 778.501 265.225C778.33 265.7 778.031 266.166 777.611 266.613C776.909 267.359 775.88 268.036 774.583 268.607C773.287 269.177 771.747 269.63 770.053 269.939C768.492 270.223 766.828 270.38 765.142 270.403C764.903 270.407 764.709 270.213 764.709 269.974C764.709 269.735 764.903 269.542 765.142 269.538C766.57 269.515 767.979 269.381 769.301 269.14C770.757 268.874 772.08 268.485 773.194 267.995C774.308 267.505 775.192 266.923 775.795 266.282C776.118 265.939 776.357 265.582 776.51 265.218C776.719 264.717 777.148 264.262 777.691 264.262Z" fill="#C4C4C4"/>
                    <path opacity="0.3" d="M892.754 306.155C893.297 306.155 893.747 306.6 893.628 307.129C893.473 307.821 893.153 308.5 892.674 309.147C891.972 310.096 890.944 310.958 889.647 311.684C888.35 312.41 886.811 312.986 885.117 313.379C883.592 313.733 881.969 313.932 880.323 313.968C880.019 313.975 879.773 313.728 879.773 313.424C879.773 313.12 880.019 312.875 880.323 312.867C881.711 312.831 883.078 312.661 884.365 312.362C885.82 312.025 887.143 311.53 888.257 310.906C889.371 310.282 890.255 309.541 890.858 308.726C891.238 308.212 891.502 307.675 891.645 307.126C891.782 306.601 892.211 306.155 892.754 306.155Z" fill="#C4C4C4"/>
                    <defs>
                    <filter id="filter0_f_804_4074" x="0" y="0.361084" width="1163" height="557" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_804_4074"/>
                    </filter>
                    <filter id="filter1_f_804_4074" x="268.424" y="303.524" width="45.5232" height="14.9199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_804_4074"/>
                    </filter>
                    <filter id="filter2_f_804_4074" x="380.975" y="261.469" width="45.5232" height="14.9199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_804_4074"/>
                    </filter>
                    <filter id="filter3_f_804_4074" x="560.832" y="243.596" width="45.5232" height="14.9199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_804_4074"/>
                    </filter>
                    <filter id="filter4_f_804_4074" x="738.457" y="261.469" width="45.5232" height="14.9199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_804_4074"/>
                    </filter>
                    <filter id="filter5_f_804_4074" x="853.522" y="305.037" width="45.5232" height="14.9199" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="1.11713" result="effect1_foregroundBlur_804_4074"/>
                    </filter>
                    <radialGradient id="paint0_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(456.381 192.208) rotate(40.4057) scale(177.515 374.303)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <linearGradient id="paint1_linear_804_4074" x1="220.108" y1="316.209" x2="923.342" y2="302.245" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00833321" stop-color="#024848"/>
                    <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_804_4074" x1="902.116" y1="291.632" x2="238.541" y2="291.632" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00110F"/>
                    <stop offset="1" stop-color="#0C2421"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_804_4074" x1="369.381" y1="347.653" x2="784.304" y2="339.027" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00833321" stop-color="#024848"/>
                    <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_804_4074" x1="744.656" y1="370.389" x2="410.44" y2="370.389" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00110F"/>
                    <stop offset="1" stop-color="#0C2421"/>
                    </linearGradient>
                    <linearGradient id="paint5_linear_804_4074" x1="447.72" y1="380.498" x2="711.224" y2="373.532" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00833321" stop-color="#024848"/>
                    <stop offset="1"/>
                    </linearGradient>
                    <linearGradient id="paint6_linear_804_4074" x1="697.354" y1="391.833" x2="462.459" y2="391.833" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00110F"/>
                    <stop offset="1" stop-color="#0C2421"/>
                    </linearGradient>
                    <radialGradient id="paint7_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(857.795 306.208) rotate(30.4675) scale(21.6123 40.3501)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint8_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 305.883) rotate(30.4675) scale(20.3655 38.0222)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint9_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(273.632 305.883) rotate(30.4674) scale(20.3655 38.0222)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint10_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(383.256 261.956) rotate(30.4675) scale(23.2748 43.454)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint11_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(386.183 263.829) rotate(30.4675) scale(20.3655 38.0222)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint12_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(566.04 245.955) rotate(30.4675) scale(20.3655 38.0222)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint13_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(743.665 263.829) rotate(30.4675) scale(20.3655 38.0222)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint14_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(858.73 307.397) rotate(30.4675) scale(20.3655 38.0222)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint15_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(563.116 244.082) rotate(30.4675) scale(23.2748 43.454)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <radialGradient id="paint16_radial_804_4074" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(740.738 261.956) rotate(30.4675) scale(23.2748 43.454)">
                    <stop stop-color="#67FFFF"/>
                    <stop offset="1" stop-color="#00ADAD"/>
                    </radialGradient>
                    <linearGradient id="paint17_linear_804_4074" x1="567.364" y1="390.361" x2="567.364" y2="953.861" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00FFFF" stop-opacity="0.3"/>
                    <stop offset="1" stop-color="#C4C4C4" stop-opacity="0"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="resp_cntr_box">
                <router-link to="/invest" class="zindx">
                    <div class="resp_cntr_inr_box">
                        <h6>Invest</h6>
                        <p>Empower businesses with a positive social impact</p>
                        <!-- <router-link to="/invest">
                            <button class="cntr_btn">Invest</button>
                        </router-link> -->
                    </div>
                </router-link>
                <router-link to="/raise" class="zindx">
                    <div class="resp_cntr_inr_box">
                        <h6>Raise</h6>
                        <p>Fuel your rocketship, leverage the power of your community.</p>
                    </div>
                </router-link>
                <router-link to="/howitworks" class="zindx">
                    <div class="resp_cntr_inr_box">
                        <h6>Learn</h6>
                        <p>Join our cosmos and vibe with like minded explorers</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
     <!-- From the Creators of ReadOn
     <div class="home_creatrors">
        <h1 class="home_creatrors-heading">From the Creators of ReadOn</h1>
            <div class="home_creatrors_wrapper">
                <div class="home_creatrors_col">
                    <img src="../assets/image/Pratibha.png" alt="" class="home_creatrors_profile-img">
                    <h2 class="home_creatrors-name">Pratibha Jain</h2>
                    <p class="home_creatrors-dec">co-founder</p>
                    <a href="https://www.linkedin.com/in/pratibhajain2" target="_blank" class="home_creatrors_social-link">
                        <img src="../assets/image/linkedin.png" alt="linkedin" class="home_creatrors-social-icon">
                    </a>
                </div>
                <div class="home_creatrors_col">
                    <img src="../assets/image/Shantanu.jpeg" alt="" class="home_creatrors_profile-img">
                    <h2 class="home_creatrors-name">Shantanu Jain</h2>
                    <p class="home_creatrors-dec">Advisor</p>
                    <a href="https://www.linkedin.com/in/shantanujain13" target="_blank" class="home_creatrors_social-link">
                        <img src="../assets/image/linkedin.png" alt="linkedin" class="home_creatrors-social-icon">
                    </a>
                </div>
            </div>
    </div> -->
</div>
</template>

<script>
export default {
    // -next-line vue/multi-word-component-names
    name:'Home',

}
</script>
<style lang="postcss" scoped>
.bg_img{
    background-image: url('../assets/image/bgstar.png');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: contain;
    padding-top: 5rem;

    bottom: 0;
}
.home_main_div{ @apply container pb-40;
    .home_box{
        @apply flex justify-center;
        .col{
            .ufo_svg{
                @apply mt-9;
            }
            .box{ @apply -mt-28 z-10 bg-cardbg w-56 h-56 mb-8 text-center rounded-[1.5rem] m-auto;
                .inr_box{ @apply px-4 py-5;
                    h6{ @apply text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-gradient to-borderclr;
                    }
                    p{ @apply mt-2 mb-6 text-menutext;
                    }
                    .btn{ @apply w-full bg-fotxthd text-black p-3 rounded-[0.858rem] font-bold text-lg;
                    }
                }
            }
            /* center dvi */
            .cntr_box{ @apply -mt-72 flex items-center z-10 bg-cardbg w-72 h-72 mb-8 text-center m-auto rounded-[1.5rem];
                .cntr_inr_box{ @apply px-4 flex-auto;
                    .cntr_div_text{
                        h6{ @apply text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-gradient to-borderclr;
                        }
                        p{ @apply text-menutext text-xl pt-4 pb-4;
                        }
                        .cntr_btn{ @apply w-full bg-fotxthd text-black mt-3 p-3 rounded-[0.858rem] font-bold text-lg;
                        }
                    }
                }
            }
        }
    }
}

.home_creatrors{
    @apply bg-cardbg text-center py-10;
    .home_creatrors-heading{
        @apply text-4xl pb-0 font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
    }
    .home_creatrors_wrapper{
        @apply container py-10 flex justify-around flex-wrap items-center;
        .home_creatrors_profile-img{
            @apply w-28 h-28 mb-4 object-cover object-center rounded-xfull inline-block border-[0.147rem] border-gradient bg-gray-100
        }
        .home_creatrors-name{
            @apply text-white font-medium tracking-wider text-2xl;
        }
        .home_creatrors-dec{
            @apply text-white capitalize opacity-75 text-base font-thin mb-1;
        }
        .home_creatrors_social-link{
            @apply inline-block;

        }
        .home_creatrors-social-icon{
                @apply m-auto max-w-[1.5625em];
            }

    }
}
/* responsive */
.resp_main_div{ @apply container flex justify-center mb-20 pt-12;
    .resp_col{
        .resp_cntr_ufo_svg{ @apply -mt-28; }
        .resp_cntr_box{ @apply flex flex-col -mt-72 z-10 w-[19rem] h-auto mb-8 text-center m-auto;
          .zindx{  @apply z-[1];
            .resp_cntr_inr_box{ @apply px-4 bg-cardbg rounded-[1.25rem] mb-4 flex-auto z-10;
                h6{ @apply text-[1.456rem] font-bold pt-4 text-transparent bg-clip-text bg-gradient-to-br from-gradient to-borderclr;
                }
                p{ @apply text-menutext text-[0.875rem] pt-3 pb-4;
                }
                .cntr_btn{ @apply w-full bg-fotxthd text-black mt-3 p-3 rounded-lg font-bold text-lg;
                }
            }
          }
        }
    }
}
</style>
