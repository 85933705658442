<template>
  <div>
    <div class="heading kyc_heading" id="form">
      <h1>KYC</h1>
    </div>

    <div class="form_main_div resp_pding" id="setp1" v-show="pancardbx">
      <ul id="progressbar">
        <li class="active"><a>Personal details</a></li>
        <li><a>Bank details</a></li>
        <li><a>Agreement</a></li>
      </ul>

      <p class="stp_info">3 quick steps to become an investor on Fredo</p>
      <form class="form">
        <div class="flex_wrp">
          <div class="column">
            <label>First name (as per PAN)</label>
            <input
              class="focus:outline-none focus:shadow-outline"
              v-model="firstnamepan"
              type="text"
              placeholder="Enter your first name"
            />
          </div>
        </div>

        <div class="flex_wrp">
          <div class="column">
            <label>Last name (as per PAN)</label>
            <input
              class="focus:outline-none focus:shadow-outline"
              v-model="lastnamepan"
              type="text"
              placeholder="Enter your last name"
            />
          </div>
        </div>
        
        <div class="flex_wrp">
          <div class="column">
            <label>PAN Card Number</label>
            <input
              class="focus:outline-none focus:shadow-outline"
              v-model="pancard"
              type="text"
              placeholder="Enter your pancard number"
            />
          </div>
        </div>

        <div class="offline_pymt_div">
          <div class="btn_Div">
            <button @click.prevent="handelPanfinal">Submit</button>
          </div>
        </div>
      </form>
    </div>

    <div class="form_main_div resp_pding" v-show="bankbx">
      <ul id="progressbar">
        <li class="active"><a>Personal details</a></li>
        <li class="active"><a>Bank details</a></li>
        <li><a>Agreement</a></li>
      </ul>

      <p class="stp_info">3 quick steps to become an investor on Fredo</p>
      <form class="kyc_stp_2_form">
        <div class="kyc_stp_2_column">
          <label>Beneficiary name</label>
          <input
            class="focus:outline-none focus:shadow-outline"
            v-model="beneficiaryname"
            type="text"
            placeholder="Enter your name"
          />
        </div>

        <div class="kyc_stp_2_column">
          <label>Bank name</label>
          <input
            class="focus:outline-none focus:shadow-outline"
            v-model="bankname"
            type="text"
            placeholder="Enter bank name"
          />
        </div>

        <div class="kyc_stp_2_column">
          <label>Bank Account Number</label>
          <input
            class="focus:outline-none focus:shadow-outline"
            v-model="bankacnumber"
            type="text"
            placeholder="Enter Bank account number"
          />
        </div>

        <div class="kyc_stp_2_column">
          <label>Bank IFSC Code</label>
          <input
            class="focus:outline-none focus:shadow-outline"
            v-model="bankifsc"
            type="text"
            placeholder="Enter IFSC Code"
          />
          <p class="fieldnote_kyc">
            Note:<span> All fields are compulsory</span>
          </p>
        </div>

        <div class="offline_pymt_div">
          <div class="btn_Div">
            <button @click.prevent="handelBankAdd">Submit</button>
          </div>
        </div>
      </form>
    </div>

    <div class="aggrement_main_div" v-show="agerbx">
      <ul id="progressbar">
        <li class="active"><a>Personal details</a></li>
        <li class="active"><a>Bank details</a></li>
        <li class="active"><a>Agreement</a></li>
      </ul>

      <p class="stp_info">3 quick steps to become an investor on pod</p>

      <div class="aggrement_note">
        <label>Declaration of risk</label>
        <p>
          By accessing/using the Fredo platform through the website [link]
          (“Website”), you bear the fitness to undertake the risks in
          investments through the Website including but not limited to the
          following
        </p>
      </div>

      <div class="aggrement_note">
        <label>Loss of Capital</label>
        <p>
          Investments in startups/early-stage ventures (“Companies”) bear an
          inherent risk of not assuring full-fledged profits or returns from the
          investments, since these companies may not have a business model or
          established concept which can be used as a reference for 100% success.
          It is for this reason that it is generally recommended to create a
          diversified portfolio of investments, which will have the potential to
          deliver gains and absorb capital losses in the aggregate.
        </p>
      </div>

      <div class="aggrement_note">
        <label>Lack of Liquidity</label>
        <p>
          Liquidity refers to equity shares that can be sold with ease. However,
          equity investments in the Companies are highly illiquid as the shares
          of such Companies are unlisted/private and cannot be sold easily on an
          exchange or similar secondary trading platform. Rarity of Dividends
          The Companies may most likely be unable to pay any dividend throughout
          the life cycle of an investment. Therefore, in order for you to earn a
          return out of any of your investments, you will have to go through a
          further sale or such other similar process for which a time frame
          cannot be ascertained.
        </p>
      </div>

      <div class="aggrement_note">
        <label>Dilution</label>
        <p>
          The Companies may raise additional capital in the future and
          therefore, your shareholding may be diluted, as a result of such issue
          of new shares. Performance The Company’s forward-looking statements,
          containing opinions and beliefs, are based on a number of estimates
          and assumptions that are subject to significant business, economic,
          regulatory, and competitive uncertainties. Though these statements can
          be used for understanding the objectives and goals of the Companies,
          such statements should not be considered as undertakings from the
          Companies and should be considered as merely being speculative and
          having subjective nature.
        </p>
      </div>

      <div class="aggrement_note">
        <label>Tax</label>
        <p>
          You may be liable to pay taxes on any dividends or gains you receive
          from your investments in the Company and payment of such taxes is
          entirely your responsibility. Therefore, you should consult your tax
          advisor for more information on these matters. For the avoidance of
          doubt, in light of your acknowledgment of the above risk factors, you
          agree and acknowledge that you shall hold Fredo harmless and shall not
          raise any claim in respect of any of the above.
        </p>
      </div>

      <div class="agremnt_checkbox">
        <input
          type="checkbox"
          id="agrement"
          v-model="checkAgg"
          class="regular-checkbox"
        />
        <label for="agrement"></label>
        <label for="agrement">Accept All Agreement Condition</label>
      </div>

      <div class="offline_pymt_div">
        <div class="btn_Div">
          <button
            @click.prevent="handelKycfinal()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="kyc_main_div" v-show="kycSubmit">
    <div class="kyc_div">
      <img src="../assets/image/rightchek.png" alt="" />
      <p>KYC Completed now you <br />can start investing</p>
    </div>
    <div class="offline_pymt_div2">
      <div class="btn_div">
        <router-link to="/invest" class="btn">View Deals</router-link>
      </div>
    </div>   
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'kyc',
  data() {
    return {
      firstnamepan: '',
      lastnamepan: '',
      pancard: '',
      beneficiaryname: '',
      bankacnumber: '',
      bankifsc: '',
      bankname: '',
      pancardbx: true,
      agerbx: false,
      bankbx: false,
      kycSubmit: false,
      checkAgg: false
    }
  },
  async mounted() {
    if (this.$store.state.user && this.$store.state.user.kyc_step) {
      await this.$store.dispatch('getStepKyc', this.$store.state.user.kyc_step)
      localStorage.setItem('loginUser', this.$store.state.user.kyc_step)

      switch (this.$store.getters.getStepKyc) {
        case '0':
          this.pancardbx = true
          this.bankbx = false
          break

        case '1':
          this.bankbx = true
          this.pancardbx = false
          this.agerbx = false
          break

        default:
          this.bankbx = false
          this.pancardbx = false
          this.agerbx = true
          break
      }
    }
  },
  methods: {
    async handelPanfinal() {
      this.isLoading = true
      await axios
        .post(
          'kyc-step-1',
          {
            first_name: this.firstnamepan,
            last_name: this.lastnamepan,
            pancard_number: this.pancard
          },
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('Token') 
            }
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.pancardbx = false
            this.bankbx = true
            this.isLoading = false
            this.kycSubmit = true
            this.agerbx = false
            this.$toast.open({
              message: response.data.msg,
              type: 'success',
              duration: 5000,
              dismissible: true
            })
          } else {
            this.isLoading = false
            this.$toast.open({
              message: response.data.msg,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.$toast.open({
            message: err,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
    },
    async handelBankAdd() {
      this.isLoading = true
      await axios
        .post(
          'kyc-step-2',
          {
            beneficiary_name: this.beneficiaryname,
            bank_name: this.bankname,
            ifsc_code: this.bankifsc,
            account_number: this.bankacnumber
          },
          {
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('Token') 
            }
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.bankbx = false
            this.agerbx = true
            this.isLoading = false
            this.$toast.open({
              message: response.data.msg,
              type: 'success',
              duration: 5000,
              dismissible: true
            })
          } else {
            this.isLoading = false
            this.$toast.open({
              message: response.data.msg,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.$toast.open({
            message: err,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
    },
    async handelKycfinal() {
      if (this.checkAgg == true) {
        this.isLoading = true
        await axios
          .post(
            'kyc-step-3',
            {
              agreement: 'true'
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + localStorage.getItem('Token') 
              }
            }
          )
          .then((response) => {
            if (response.data.status == 200) {              
              this.bankbx = false
              this.pancardbx = false
              this.agerbx = false
              this.kycSubmit = true
              this.isLoading = false
              this.$toast.open({
                message: response.data.msg,
                type: 'success',
                duration: 5000,
                dismissible: true
              })
            } else {
              this.isLoading = false
              this.$toast.open({
                message: response.data.msg,
                type: 'error',
                duration: 5000,
                dismissible: true
              })
            }
          })
          .catch((err) => {
            this.isLoading = false
            this.$toast.open({
              message: err,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          })
      } else {
        this.$toast.open({
          message: 'Please accept agreement and condition.',
          type: 'error',
          duration: 5000,
          dismissible: true
        })
      }
    }
  },
  watch: {
    checkAgg: function (val) {
      this.checkAgg = val
    }
  }
}
</script>
<style>
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 30px;
}
#progressbar li {
  list-style-type: none;
  color: white;
  font-size: 12px;
  width: 21%;
  float: left;
  position: relative;
}
#progressbar li a {
  position: absolute;
  top: -1.5rem;
  left: 50%;
  color: #acacac;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
#progressbar li:before {
  content: '.';
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: #acacac;
  background: #acacac;
  border-radius: 50%;
  margin: 0 auto 5px auto;
  z-index: 1;
}

#progressbar li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #acacac;
  position: absolute;
  color: #00ffff;
  left: -43%;
  top: 9px;
  z-index: 0;
}
#progressbar li:first-child:after {
  content: none;
}
#progressbar li.active:before,
#progressbar li.active:after {
  background: #00ffff;
}
#progressbar li.active a {
  color: #00ffff;
}
p.stp_info {
  color: #acacac;
  text-align: center;
  margin-bottom: 3rem;
}
/* Agreement */
/* Agreement */
.agremnt_checkbox {
  display: flex;
  align-items: center;
}
.agremnt_checkbox label {
  color: #ffffff;
  font-size: 0.9rem;
}
.regular-checkbox {
  display: none;
}
.regular-checkbox + label {
  background-color: #373737;
  padding: 10px;
  border-radius: 6px;
  display: inline-block;
  position: relative;
  margin-right: 9px;
}
.regular-checkbox:checked + label {
  background-color: #373737;
  color: #99a1a7;
}
.regular-checkbox:checked + label:after {
  content: '\2714';
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 4px;
  color: #00ffff;
}
</style>
<style lang="postcss" scoped>
.heading {
  @apply flex flex-wrap w-full mb-10 flex-col items-center text-center;
  h1 {
    @apply text-6xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2;
  }
}
.form_main_div {
  @apply max-w-[49.563rem] rounded-[0.5rem] bg-cardbg2 mx-auto p-14 my-20 mb-40;
  .form {
    @apply w-full;
    .flex_wrp {
      @apply flex flex-wrap justify-center -mx-3 mb-6;
      .column {
        @apply w-full md:w-full px-14 mb-6 md:mb-0 my-8;
        label {
          @apply block capitalize  text-clr8 text-xxs font-medium mb-2;
        }
        input {
          @apply w-full bg-cardbg3 text-white  p-3 rounded-[0.5rem];
        }
      }
    }
  }
  .kyc_stp_2_form {
    @apply md:w-full m-auto;
    .kyc_stp_2_column {
      @apply w-full  px-14 mb-6 md:mb-0 my-8;
      label {
        @apply block capitalize  text-clr8 text-xxs font-medium mb-2;
      }
      input {
        @apply w-full bg-cardbg3 text-white  p-3  rounded-[0.5rem];
      }
    }
  }
}
.aggrement_main_div {
  @apply max-w-[49.563rem] rounded-[0.5rem] bg-cardbg2 mx-auto p-12 my-28 mb-40;
  .aggrement_note {
    label {
      @apply text-white opacity-75 mb-5 font-medium;
    }
    p {
      @apply text-white opacity-75 mb-5 font-thin;
    }
  }
}
.kyc_main_div {
  @apply max-w-[49.563rem]  rounded-[0.5rem] bg-cardbg2 mx-auto p-20 my-4;
  .kyc_div {
    @apply flex justify-center items-center;
    p {
      @apply text-3xl text-center  text-menutext ml-8;
    }
  }
}
/* button */
.offline_pymt_div {
  @apply flex justify-center items-center;
  .btn_div {
    @apply text-center mt-7;
    .btn {
      @apply inline-flex text-black font-bold bg-gradient-to-br from-invbtn to-invbtn2 border-0 py-3 px-20 focus:outline-none rounded-[1rem] text-lg;
      &:hover {
        @apply hover:bg-gradient-to-br from-hover to-hover;
      }
    }
  }
}
.offline_pymt_div2 {
  @apply flex justify-evenly items-center pb-16;
  .btn_div {
    @apply text-center mt-7;
    .btn {
      @apply inline-flex text-black font-bold bg-gradient-to-br from-invbtn to-invbtn2 border-0 py-3 px-20 focus:outline-none rounded-[1rem] text-lg;
      &:hover {
        @apply hover:bg-gradient-to-br from-hover to-hover;
      }
    }
  }
}
</style>
